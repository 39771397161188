import styled from 'styled-components'

const TagOld = styled.span`
	color: ${props => props.color ? props.color : props.theme.textSlightlyLight};
	font-size: 0.9rem;
	font-weight: bold;
	text-transform: uppercase;

	${props => props.dark && `
		background: rgba(100, 100, 100, 0.7);
		color: #FFF;
	`}
`

export default TagOld
