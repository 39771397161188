import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import Route from '../components/router/Route'
import {
	About,
	Admin,
	AlternateArtworks,
	Archetype,
	Archetypes,
	ChangelogPermalink,
	Changelog,
	Card,
	Checkout,
	CollectionPage,
	Coupon,
	Community,
	CurrentFormat,
	DecksIndex,
	DowngradeSuccessful,
	EmailNotVerified,
	Event,
	EventPlayerForm,
	Events,
	Format,
	Formats,
	Home,
	Imprint,
	Install,
	Login,
	NotFound,
	OcgOnly,
	PasswordReset,
	PaymentMethodUpdated,
	Player,
	Privacy,
	Register,
	Results,
	ResumeSuccessful,
	Set,
	Sets,
	SetsWithMissingImages,
	Settings,
	SetWithMissingImages,
	SiteSearch,
	Spoiler,
	SwitchSuccessful,
	Terms,
	UpgradeSuccessful,
	User,
	Support,
	VerifyEmail,
	Welcome,
	CountryInvalid,
	Decks,
	MyDecks,
	CollectionTargetForm,
	MyCollections,
	CollectionAddPage,
	CollectionForm,
	Pricing,
	Roadmap,
	DownloadCardImages,
	MyFavorites,
	CollectionTarget,
	MigratePrices,
	ProductCollectionManager,
	CardImagesPartner,
	MasterDuelMissingCards,
	Rarities,
	Test,
	MyAccount
} from '../pages/imports'
import DeckLoader from '../pages/deck/DeckLoader'
import PrivateRoute from '../components/router/PrivateRoute'
import Modal from '../components/router/Modal'
import TabSearch from '../pages/search/CardSearch/TabSearch'
import TabDeck from '../pages/deck/TabDeck'
import { getPageLocation, hideOverlay } from '../helper/router'
import { updateUserSetting } from '../state/actionCreators/app'
import EditColumns from '../pages/collection/settings/EditColumns'

const Routes = ({ location, match, game, updateUserSetting, userLanguage }) => {
	// keep current page in background while browsing overlays
	const pageLocation = getPageLocation(location)

	const setGame = () => {
		const matchGame = match.params.game || 'ygo'

		if (game !== matchGame) {
			updateUserSetting('site.game', matchGame)
		}
	}

	useEffect(() => {
		setGame()
	}, [match.params.game])

	return (
		<>
			{/* Pages */}
			<Switch location={pageLocation}>
				<Route path="/" exact component={Home} />

				{/* User generated content & user specific pages */}
				<Route path="/:tab(add)/:collectionSlug?" component={CollectionAddPage} />
				<Route path="/card/:facet/decks" component={Decks} />
				<Route path="/:tab(collection)/:collectionSlug/targets/:targetSlug" component={CollectionTarget} />
				<Route path="/:tab(collection)/:collectionSlug/:mode(card)/:id" component={CollectionPage} />
				<Route path="/:tab(collection)/:collectionSlug/:mode(targets|download|upload|imports)" component={CollectionPage} />
				<Route path="/:tab(collection)/:collectionSlug" component={CollectionPage} />
				<Route path="/community/:tab(following)" component={MyDecks} />
				<Route path="/community/(unread|subscribed|rejected)?" component={Community} />
				<Route path="/deck/:slug/:navigationTab(prices|test|comment)?/:commentId?" exact component={DeckLoader} />
				<Route path="/deck-builder/:slug/:modal?" exact component={DeckLoader} />
				<Route path="/decks/:format(master-duel|ocg|speed-duel|tcg-traditional)?" exact component={DecksIndex} />
				<Route path="/decks/:format(master-duel|ocg|speed-duel|tcg-traditional)?/:facet/:time?" component={Decks} />
				<PrivateRoute path="/myaccount" component={MyAccount} />
				<Route path="/mycollections/selectPriceSource" component={MigratePrices} />
				<Route path="/mycollections/collection/:slug?" component={CollectionForm} />
				<Route path="/mycollections" exact component={MyCollections} />
				<Route path="/myfavorites" component={MyFavorites} />
				<Route path="/mydecks/search/:q" component={MyDecks} />
				<Route path="/mydecks/:tab?" component={MyDecks} />
				<Route path="/user/:slug" component={User} />
				<Route path="/settings" component={Settings} />
				<Route path="/submit/:id?" component={EventPlayerForm} />

				{/* B2B */}
				<PrivateRoute path="/download-card-images" component={DownloadCardImages} />

				{/* Collection Manager pages */}
				<Route path="/:type(archetype|series|theme)/:slug/:tab(collection|add|sets)?/:collectionSlug?" component={Archetype} />
				<Route path="/card/:slug/:tab(collection|add)/:collectionSlug?" component={Card} />
				<Route path="/card/:slug/:print?" exact component={Card} />
				<Route path="/set/:format(master-duel|ocg|speed-duel)/:slug/:tab(collection|add)?/:collectionSlug?" component={Set} />
				<Route path="/set/:slug/:tab(collection|add)?/:collectionSlug?" component={Set} />
				<Route path="/deck/:slug/:navigationTab(prices)/:tab(collection|add)?/:collectionSlug?" exact component={DeckLoader} />

				{/* Database */}
				<Route path="/alternateartworks" component={AlternateArtworks} />
				<Route path="/cards/spoiler" component={Spoiler} />
				<Route path="/cards/master-duel-missing-cards" component={MasterDuelMissingCards} />
				<Route path="/cards/ocgonly" component={OcgOnly} />
				<Route path="/format/:type/:date" component={Format} />
				<Route path="/formats/:type?" component={Formats} />
				<Route path="/limited-list/:type?" component={CurrentFormat} />
				<PrivateRoute path="/missing/:language?" component={SetsWithMissingImages} />
				<PrivateRoute path="/missinglist/:language/:slug" component={SetWithMissingImages} />
				<Route path="/player/:slug" component={Player} />
				<Route path="/search/:q/:category(decks)" component={Decks} />
				<Route path="/search/:q?/:category?" component={SiteSearch} />
				<Route path="/sets/:category?/:category2?/:category3?/:category4?" component={Sets} />
				<Route path="/rarities" component={Rarities} />
				<Route path="/themes/:type?" component={Archetypes} />
				<Route path="/tournament/:eventSlug/submit" component={EventPlayerForm} />
				<Route path="/tournament/:slug" exact component={Event} />
				<Route path="/tournaments/upcoming/:category?" component={Events} />
				<Route path="/tournaments/:category?" component={Results} />

				{/* User auth & sign up */}
				<Route path="/login" component={Login} />
				<Route path="/passwordreset/:token?" component={PasswordReset} />
				<Route path="/signup" component={Register} />
				<PrivateRoute path="/welcome/:step?/:token?" component={Welcome} />
				<Route path="/verifyemail/:token?" component={VerifyEmail} />

				{/* Subscription */}
				<Route path="/coupon/:code" component={Coupon} />
				<Route path="/voucher/:code" component={Coupon} />
				<PrivateRoute path="/purchase/:price?" exact component={Checkout} />
				<PrivateRoute path="/purchase/info/emailNotVerified" component={EmailNotVerified} />
				<PrivateRoute path="/purchase/info/countryInvalid" component={CountryInvalid} />
				<PrivateRoute path="/purchase/info/upgraded" component={UpgradeSuccessful} />
				<PrivateRoute path="/purchase/info/switched" component={SwitchSuccessful} />
				<PrivateRoute path="/purchase/info/downgraded" component={DowngradeSuccessful} />
				<PrivateRoute path="/purchase/info/paymentMethodUpdated" component={PaymentMethodUpdated} />
				<PrivateRoute path="/purchase/info/resumed" component={ResumeSuccessful} />

				{/* Admin */}
				<Route path="/admin" component={Admin} />

				{/* Miscellaneous */}
				<Route path="/about" component={About} exact />
				<Route path="/changelog" component={Changelog} exact />
				<Route path="/changelog/:slug" component={ChangelogPermalink} />
				<Route path="/install" component={Install} />
				<Route path="/legal" component={Imprint} />
				<Route path="/roadmap/idea/:id" component={Roadmap} />
				<Route path="/roadmap/:status?" component={Roadmap} />
				<Route path="/privacy" component={Privacy} />
				<Route path="/pricing/:yearly?" component={Pricing} />
				<Route path="/product/collection-manager" component={ProductCollectionManager} />
				<Route path="/support/:presetType?" component={Support} />
				<Route path="/terms" component={Terms} />

				<Route path="/partner/card-images" component={CardImagesPartner} />

				<Route path="/cards/:collectionSlug?" component={null} exact />
				<Route path="/white" component={null} />

				<Route path="/test" component={Test} exact />

				<Route component={NotFound} />
			</Switch>

			<Modal
				path="/target/:slug?"
				onClose={hideOverlay}
				onClickAway={hideOverlay}
			>
				<CollectionTargetForm />
			</Modal>

			<EditColumns visible />

			<TabSearch location={pageLocation} />
			<TabDeck location={pageLocation} />
		</>
	)
}

Routes.propTypes = {
	location: PropTypes.object.isRequired,
	game: PropTypes.string,
	match: PropTypes.object.isRequired,
	updateUserSetting: PropTypes.func.isRequired,
	userLanguage: PropTypes.string.isRequired
}

Routes.defaultProps = {
	game: null
}

const mapStateToProps = state => ({
	game: state.persist.user.settings['site.game'],
	userLanguage: state.persist.language
})

const mapDispatchToProps = dispatch => bindActionCreators({
	updateUserSetting
}, dispatch)

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter
)

export default enhance(Routes)
