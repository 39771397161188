const types = [
	{
		value: 'monster',
		name: 'general.monster',
		namePlural: 'general.monsterPlural',
		modestColor: 'rgb(139,98,32, 0.3)',
		lightColor: '#c4c4c4',
		darkColor: '#787878'
	},
	{
		value: 'spell',
		name: 'general.spell',
		namePlural: 'general.spellPlural',
		modestColor: 'rgb(140, 185, 192, 0.3)',
		lightColor: '#8cb9c0',
		darkColor: '#134D74',
		iconColor: '#0e9180'
	},
	{
		value: 'trap',
		name: 'general.trap',
		namePlural: 'general.trapPlural',
		modestColor: 'rgb(182, 135, 150, 0.3)',
		lightColor: '#b68796',
		darkColor: '#731433',
		iconColor: '#af267c'
	},
	{
		value: 'skill',
		name: 'general.skill',
		namePlural: 'general.skillPlural',
		modestColor: 'rgb(28, 155, 224, 0.3)',
		lightColor: '#1C9BE0',
		darkColor: '#3E59A8'
	},
	{
		value: 'other',
		name: 'general.other',
		namePlural: 'general.otherPlural',
		lightColor: '#c4c4c4',
		darkColor: '#787878'
	}
]

export default types
