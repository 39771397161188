import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Icon from '../general/Icon'
import ZoomableImage from '../card/name/ZoomableImage'
import RarityAbbr from '../card/name/RarityAbbr'
import Rarity from '../card/Rarity'
import Artwork, { StyledArtwork } from '../card/Artwork'
import CoverImage, { StyledCoverImage } from '../../pages/deck/components/CoverImage'
import { StyledPrint } from '../card/Print'
import SetNumber from '../set/SetNumber'

const SelectLabel = ({ data, option }) => (
	<StyledSelectLabel
		disabled={data.isDisabled}
		isSelected={data.isSelected}
		empty={data.data.empty || data.data.create}
		option={option ? 1 : undefined}
		layer={data.data.layer}
	>
		{data.data.layer && <Icon name="subdirectory-right" />}
		{data.data.icon && <Icon name={data.data.icon} />}
		{data.data.setNumber && <SetNumber small>{data.data.setNumber}</SetNumber>}
		{data.data.rarity && <RarityAbbr id={data.data.rarity} />}
		{data.data.coverCard && <CoverImage small card={data.data.coverCard} ignoreUserSubscribed />}
		{data.data.coverArtwork && <CoverImage small card={data.data.coverArtwork} ignoreUserSubscribed />}
		{data.data.cardImage && <ZoomableImage hover={false} image={data.data.cardImage} />}
		{!data.selectProps.iconOnly && <span>{data.data.label}</span>}
		{!Number.isNaN(data.data.number) && data.data.number !== 0 && (
			<SelectLabelNumber>
				{data.data.number?.toLocaleString()}
				{data.data.artworkNumber && data.data.artworkNumber > 1 && <Artwork number={data.data.artworkNumber} />}
			</SelectLabelNumber>
		)}
	</StyledSelectLabel>
)

SelectLabel.propTypes = {
	data: PropTypes.object.isRequired,
	option: PropTypes.bool
}

SelectLabel.defaultProps = {
	option: false
}

export const StyledSelectLabel = styled.div`
	align-items: center;
	display: flex;
	text-align: left;

	svg, img {
		margin-right: 0.5rem;
		min-width: 20px;
	}

	${SetNumber} {
        margin-right: 0.5rem;
	}

	${StyledCoverImage} {
		margin: 2px;
	}

	${Rarity} {
		margin-right: 0.5rem;

		& + span {
			font-size: 0.9rem;
		}
	}

	${props => props.option && props.disabled && `
		color: ${props.theme.textLight};
		opacity: 0.5;
	`}

	${props => props.option && props.empty && !props.isSelected && `
		color: ${props.theme.textLight};
		font-style: italic;
	`}

	${props => props.isSelected && `
		${StyledPrint} {
			color: inherit;
		}
	`}

	${props => props.layer && `
		svg {
			color: ${props.theme.textLight};
			height: 12px;
			width: 12px;
			min-width: 12px;
		}
	`}

	${props => props.layer === 3 && `
		padding-left: 1rem;
	`}
`

export const SelectLabelNumber = styled.div`
	color: ${props => props.theme.textLight};
	font-size: 0.8rem;
	font-weight: 500;

	margin-left: auto;
	padding-left: 0.5rem;
	white-space: nowrap;

	${StyledArtwork} {
		margin-left: 0.35rem;

		svg {
			min-width: 16px;
			margin-right: 0.25rem;
		}
	}
`

export default SelectLabel
