import React from 'react'
import loadable from '@loadable/component'
import ChunkLoading from '../layout/Loading/ChunkLoading'

const options = {
	fallback: <ChunkLoading />
}

// general
export const Home = loadable(() => import('./home/Home'), options)
export const Front = loadable(() => import('./home/Front'), options)
export const Changelog = loadable(() => import('./misc/changelog/Changelog'), options)
export const ChangelogPermalink = loadable(() => import('./misc/changelog/ChangelogPermalink'), options)
export const NotFound = loadable(() => import('../layout/Loading/NotFound'), options)

// admin
export const Admin = loadable(() => import('./admin'), options)

// alternate artworks
export const AlternateArtworks = loadable(() => import('./database/AlternateArtworks'), options)

// archetype
export const Archetypes = loadable(() => import('./archetype/Archetypes'), options)
export const Archetype = loadable(() => import('./archetype/Archetype'), options)
export const Rarities = loadable(() => import('./rarity/Rarities'), options)

// community
export const Community = loadable(() => import('./community/Community'), options)

// collection
export const MyCollections = loadable(() => import('./collection/MyCollections'), options)
export const MyAccount = loadable(() => import('./user/MyAccount'), options)
export const MyDecks = loadable(() => import('./deck/MyDecks'), options)
export const MyFavorites = loadable(() => import('./favorites/MyFavorites'), options)
export const CollectionPage = loadable(() => import('./collection/CollectionPage'), options)
export const CollectionForm = loadable(() => import('./collection/CollectionForm'), options)
export const CollectionAddPage = loadable(() => import('./collection/add/CollectionAddPage'), options)
export const CollectionHistory = loadable(() => import('./collection/history/CollectionHistory'), options)
export const CollectionTarget = loadable(() => import('./collection/targets/CollectionTarget'), options)
export const MigratePrices = loadable(() => import('./collection/edit/MigratePrices'), options)

// b2b
export const DownloadCardImages = loadable(() => import('./scans/DownloadCardImages'), options)

// deck
export const DecksIndex = loadable(() => import('./decks/index'), options)
export const Decks = loadable(() => import('./decks/Decks'), options)
export const EventPlayerForm = loadable(() => import('./tournament/EventPlayerForm'), options)

// event
export const Events = loadable(() => import('./tournament/Upcoming'), options)
export const Results = loadable(() => import('./tournament/Past'), options)
export const Event = loadable(() => import('./tournament/Tournament'), options)

// misc
export const Contact = loadable(() => import('./user/Support'), options)
export const Imprint = loadable(() => import('./misc/Imprint'), options)
export const Privacy = loadable(() => import('./misc/Privacy'), options)
export const Terms = loadable(() => import('./misc/Terms'), options)
export const Install = loadable(() => import('./misc/Install'), options)
export const About = loadable(() => import('./misc/About'), options)
export const ProductCollections = loadable(() => import('./product/Collections'), options)
export const ProductCollectionManager = loadable(() => import('./product/CollectionManager'), options)
export const Team = loadable(() => import('./misc/Team'), options)
export const Pricing = loadable(() => import('./product/Pricing'), options)
export const Roadmap = loadable(() => import('./misc/Roadmap'), options)

// card
export const Card = loadable(() => import('./card/Card'), options)
export const Spoiler = loadable(() => import('./database/Spoiler'), options)
export const Cards = loadable(() => import('./card/Cards'), options)
export const OcgOnly = loadable(() => import('./database/OcgOnly'), options)
export const MasterDuelMissingCards = loadable(() => import('./database/MasterDuelMissingCards'), options)

// format
export const Formats = loadable(() => import('./database/format/Formats'), options)
export const Format = loadable(() => import('./database/format/Format'), options)
export const CurrentFormat = loadable(() => import('./database/format/CurrentFormat'), options)

// player
export const Player = loadable(() => import('./player/Player'), options)

// search
export const SiteSearch = loadable(() => import('./search/Search'), options)

// set
export const Sets = loadable(() => import('./set/Sets'), options)
export const Set = loadable(() => import('./set/Set'), options)
export const SetsWithMissingImages = loadable(() => import('./set/SetsWithMissingImages'), options)
export const SetWithMissingImages = loadable(() => import('./set/SetWithMissingImages'), options)

// subscription
export const Checkout = loadable(() => import('./purchase/Checkout'), options)
export const Coupon = loadable(() => import('./purchase/Coupon'), options)
export const EmailNotVerified = loadable(() => import('./purchase/info/EmailNotVerified'), options)
export const CountryInvalid = loadable(() => import('./purchase/info/CountryInvalid'), options)
export const UpgradeSuccessful = loadable(() => import('./purchase/info/UpgradeSuccessful'), options)
export const SwitchSuccessful = loadable(() => import('./purchase/info/SwitchSuccessful'), options)
export const DowngradeSuccessful = loadable(() => import('./purchase/info/DowngradeSuccessful'), options)
export const PaymentMethodUpdated = loadable(() => import('./purchase/info/PaymentMethodUpdated'), options)
export const ResumeSuccessful = loadable(() => import('./purchase/info/ResumeSuccessful'), options)
export const CancelForm = loadable(() => import('./purchase/CancelForm'), options)
export const Onboarding = loadable(() => import('./collection/Onboarding'), options)

// user
export const Login = loadable(() => import('./user/Auth/Login'), options)
export const PasswordReset = loadable(() => import('./user/Auth/PasswordReset'), options)
export const Register = loadable(() => import('./user/Auth/Register'), options)
export const Settings = loadable(() => import('./user/Settings'), options)
export const Support = loadable(() => import('./user/Support'), options)
export const User = loadable(() => import('./user/User'), options)
export const VerifyEmail = loadable(() => import('./user/Auth/VerifyEmail'), options)
export const Welcome = loadable(() => import('./user/Auth/Welcome'), options)

export const CardSearch = loadable(() => import('./search/CardSearch/index'), options)
export const CollectionTargetForm = loadable(() => import('./collection/targets/CollectionTargetForm'), options)
export const CardImagesPartner = loadable(() => import('./partner/CardImagesPartner'), options)
export const Test = loadable(() => import('./misc/Test'), options)
