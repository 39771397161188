import styled from 'styled-components'
import { mobile } from '../../styles/media'

const H2 = styled.div`
	color: ${props => props.theme.text};
	font-weight: 600;
	font-size: 1.5rem;
	margin-bottom: 1rem;

	${mobile`
		font-size: 1.3rem;
	`}
`

export default H2
