import React from 'react'
import isBefore from 'date-fns/isBefore'
import plans from '../data/plans'
import { domains, translationsMapping, titleSuffix } from '../../config'
import { events, trackDownload, trackEvent } from './track'
import { post } from './api'
import { copyToClipboard, formatPlace } from './string'
import Marker from '../components/deck/Marker'
import { getCardName, groupDeckCards } from './card'
import { month } from './date'
import formats from '../data/formats'
import { removeEventPlayers } from '../state/actions/deckbuilder'

export const getDeckCards = (deck, priceSource, prices, cardNameLanguage, ownsDeck) => {
	let deckCards = []

	const placeOrder = {
		main: 1,
		extra: 2,
		side: 3,
		note: 4
	}

	if (deck) {
		const appliedHaves = {}

		deckCards = deck.cards.sort((a, b) => {
			if (placeOrder[a.place] > placeOrder[b.place]) return 1
			if (placeOrder[a.place] < placeOrder[b.place]) return -1

			return a.order - b.order
		}).map((deckCard) => {
			let ownsCard = null
			let price = null

			if (deck.haves) {
				// haves based on artworks lead to confusion
				const key = /*deckCard.artworkId ? `artwork-${deckCard.artworkId}` : */`card-${deckCard.card.id}`

				if (!(key in appliedHaves)) {
					appliedHaves[key] = deck.haves[key]
				}

				ownsCard = appliedHaves[key] > 0

				if (appliedHaves[key] > 0) {
					appliedHaves[key] = appliedHaves[key] - 1
				}
			}

			if (prices) {
				price = prices.find(item => item.card === deckCard.card.id && item.priceSource === priceSource)?.price || null
			}

			return {
				...deckCard,
				card: ownsDeck ? {
					...deckCard.card,
					image: deckCard.card ? deckCard.card[`image_${cardNameLanguage}`] || deckCard.card.image_en || deckCard.card.image : null,
					name: deckCard.card ? deckCard.card[`name_${cardNameLanguage}`] || deckCard.card.name_en : null
				} : deckCard.card,
				ownsCard,
				price
			}
		})
	}

	return deckCards
}

export const deleteDeck = (event, deck, setConfirm, removeDeck, location, t, callback = null) => {
	event.preventDefault()
	event.stopPropagation()

	setConfirm({
		text: t('general.removeDeckConfirm', { name: <Marker key={deck.name}>{deck.name}</Marker> }),
		submitText: t('general.remove'),
		cancelText: t('general.cancel'),
		success: () => {
			removeDeck(deck.slug, !!deck.archived, location, t, callback)
		}
	})
}

export const unarchiveDeck = (event, deck, setConfirm, setArchived, t, callback = null) => {
	event.preventDefault()
	event.stopPropagation()

	if (deck.hasEvent) {
		setConfirm({
			text: t('general.unarchiveEventPlayerDeckConfirm', { name: <Marker key={deck.name}>{deck.name}</Marker> }),
			submitText: t('general.unarchive'),
			cancelText: t('general.cancel'),
			success: () => {
				removeEventPlayers(deck.slug)
				setArchived(deck.slug, false, t, callback)
			}
		})
	} else {
		setArchived(deck.slug, false, t, callback)
	}
}

export const closeDeck = (openDeck, shelvedDeck, setConfirm, saveDeck, discardChanges, setDeck, clearDeck, location, viewport, t) => {
	const deck = shelvedDeck || openDeck

	if (deck && !deck.saved) {
		setConfirm({
			text: t('general.deckUnsavedWarning', { name: <Marker key={deck.name}>{deck.name}</Marker> }),
			submitText: t('general.save'),
			submitRed: false,
			secondaryText: t('general.discardChanges'),
			secondaryRed: true,
			cancelText: t('general.cancel'),
			width: '600px',
			success: () => {
				saveDeck(t, () => {
					clearDeck()
				})
			},
			secondary: () => {
				discardChanges()
			}
		})
	} else {
		clearDeck()
	}
}

export const getDeckFormatMonth = (deck) => {
	let date

	if (deck.eventPlayers && deck.eventPlayers.length > 0 && deck.eventPlayers[0].event.date) {
		date = deck.eventPlayers[0].event.date
	} else if (deck.season && deck.season.endsOn) {
		if (isBefore(new Date(deck.updatedAt), new Date(deck.season.endsOn))) {
			date = deck.updatedAt
		} else {
			date = deck.season.startsOn
		}
	} else {
		date = deck.updatedAt
	}

	return month(date)
}

export const getTitle = (deck, language, modal, t) => {
	if (deck.canEdit) {
		return `${modal === 'settings' ? `${t('general.settings')}: ` : ''}${deck.name}`
	}

	const hasResult = deck.eventPlayers && deck.eventPlayers.length > 0
	const author = ` ${t('general.by')} ${deck.user.username}`
	const date = getDeckFormatMonth(deck)

	let prefix = ''
	const suffix = hasResult ? ` (${deck.eventPlayers[0].event.name})` : ` (${date}) ${author}`

	if (hasResult && deck.eventPlayers[0].place) {
		prefix = `[${formatPlace(deck.eventPlayers[0].place, language, t)}${deck.eventPlayers[0].place === '1' || deck.eventPlayers[0].place === '2' ? ` ${t('general.placeX')}` : ''}] `
	}

	return `${prefix}${deck.name}${suffix}`
}

export const placeIsFull = (quantities, format, place, subscribed) => {
	if (place === 'main' && quantities.main === formats[format].maxCopies) {
		return true
	}

	if (place === 'extra' && quantities.extra === formats[format].extra) {
		return true
	}

	if (place === 'side' && quantities.side === formats[format].side) {
		return true
	}

	if (place === 'note' && quantities.note === (subscribed ? plans[subscribed].watchListSpace : 5)) {
		return true
	}

	if (place === 'skill' && quantities.skill === formats[format].skill) {
		return true
	}

	return false
}

export const canAddCard = (format, place, quantities, allowed, subscribed) => {
	if (quantities.total === (allowed || 3)) {
		return false
	}

	if (placeIsFull(quantities, format, place, subscribed)) {
		return false
	}

	return true
}

export const countCardQuantities = (deckCards, cardSlug, artworkId) => {
	let count

	if (artworkId) {
		count = {
			main: deckCards.filter(item => artworkId && item.artworkId === artworkId && item.place === 'main').length,
			extra: deckCards.filter(item => artworkId && item.artworkId === artworkId && item.place === 'extra').length,
			side: deckCards.filter(item => artworkId && item.artworkId === artworkId && item.place === 'side').length,
			note: deckCards.filter(item => artworkId && item.artworkId === artworkId && item.place === 'note').length,
			skill: deckCards.filter(item => artworkId && item.artworkId === artworkId && item.place === 'skill').length
		}
	} else {
		count = {
			main: deckCards.filter(item => item.card?.slug === cardSlug && item.place === 'main').length,
			extra: deckCards.filter(item => item.card?.slug === cardSlug && item.place === 'extra').length,
			side: deckCards.filter(item => item.card?.slug === cardSlug && item.place === 'side').length,
			note: deckCards.filter(item => item.card?.slug === cardSlug && item.place === 'note').length,
			skill: deckCards.filter(item => item.card?.slug === cardSlug && item.place === 'skill').length

		}
	}

	return {
		total: count.main + count.extra + count.side + count.note,
		...count
	}
}

export const getSubscribed = (deck, userSubscribed) => {
	if (!deck) {
		return userSubscribed
	}

	if (deck.user.subscribed === 'pro' || userSubscribed === 'pro') {
		return 'pro'
	}

	if (deck.user.subscribed === 'starter' || userSubscribed === 'starter') {
		return 'starter'
	}

	return null
}

export const downloadYdk = (deckSlug, language, showToast, hideToast, t) => {
	const domain = domains[translationsMapping[language]]

	showToast('info', t('general.preparingDownload'), null, true)

	post(`decks/${deckSlug}/prepareDownload`, null, (token) => {
		trackDownload(`https://${domain}/api/decks/${deckSlug}/ydk`)
		hideToast()

		if (process.env.NODE_ENV === 'development') {
			window.location.href = `http://localhost:${process.env.DEV_PORT || 3000}/api/decks/${deckSlug}/ydk?token=${token}`
		} else {
			window.location.href = `https://${domain}/api/decks/${deckSlug}/ydk?token=${token}`
		}
	})
}

export const downloadPdf = (deckSlug, decklistLanguage, language, showToast, hideToast, t) => {
	const domain = domains[translationsMapping[language]]

	showToast('info', t('general.preparingDownload'), null)

	post(`decks/${deckSlug}/prepareDownload`, null, (token) => {
		trackDownload(`https://${domain}/api/decks/${deckSlug}/pdf`)
		hideToast()

		if (process.env.NODE_ENV === 'development') {
			window.location.href = `http://localhost:${process.env.DEV_PORT || 3000}/api/decks/${deckSlug}/pdf?token=${token}&language=${decklistLanguage}`
		} else {
			window.location.href = `https://${domain}/api/decks/${deckSlug}/pdf?token=${token}&language=${decklistLanguage}`
		}
	})
}

export const copyDeckList = (deck, showToast, cardNameLanguage, t) => {
	copyDeckListToClipBoard([
		...groupDeckCards(deck.cards, 'main').sort((a, b) => a.order - b.order),
		...groupDeckCards(deck.cards, 'extra').sort((a, b) => a.order - b.order),
		...groupDeckCards(deck.cards, 'side').sort((a, b) => a.order - b.order)
	], cardNameLanguage, t)

	showToast('success', t('general.deckListCopied'))
	trackEvent(events.COPY_DECKLIST, deck.name)
}

export const copyDeckListToClipBoard = (cards, cardNameLanguage, t) => {
	let list = ''
	const places = ['main', 'extra', 'side']

	places.forEach((place) => {
		const columnCards = cards.filter(item => item.place === place)
		const quantity = columnCards.reduce((accumulator, card) => accumulator + (card.missing || card.ids.length), 0)

		if (columnCards.length > 0) {
			if (place !== 'main') {
				list += '\n'
			}

			list += `${t(`general.place_${place}`)} (${quantity})\n`

			columnCards.forEach((columnCard) => {
				list += `${columnCard.missing || columnCard.ids.length}x ${getCardName(columnCard, cardNameLanguage)}\n`
			})
		}
	})

	copyToClipboard(list)
}

export const getTcgPlayerMassEntryLink = (deckCards) => {
	const cardsArr = [
		...groupDeckCards(deckCards, 'main').sort((a, b) => a.order - b.order),
		...groupDeckCards(deckCards, 'extra').sort((a, b) => a.order - b.order),
		...groupDeckCards(deckCards, 'side').sort((a, b) => a.order - b.order)
	].map(deckCard => `${deckCard.ids.length} ${deckCard.card.name_en}`)

	return `/massentry?productline=Yugioh&c=${encodeURIComponent(cardsArr.join('||'))}`
}

export const getAssignableDeckArchetypes = (deckCards, cardNameLanguage, exclude = []) => {
	const archetypes = {}

	deckCards.forEach((deckCard) => {
		if (deckCard.place !== 'note' && deckCard.card.archetypesData && deckCard.card.archetypesData.length > 0) {
			deckCard.card.archetypesData.forEach((archetype) => {
				if (archetype.deckAssignable && exclude.indexOf(archetype.id) === -1) {
					if (!archetypes[archetype.id]) {
						archetypes[archetype.id] = {
							...archetype,
							count: 1
						}
					} else {
						archetypes[archetype.id].count += 1
					}
				}
			})
		}
	})

	return Object.values(archetypes).sort((a, b) => {
		if (a.count > b.count) return -1
		if (a.count < b.count) return 1

		return (a[`name_${cardNameLanguage}`] || a.name_en).localeCompare(b[`name_${cardNameLanguage}`] || b.name_en, 'en', {
			numeric: true,
			sensitivity: 'base'
		})
	}).map(item => ({
		...item,
		name: item[`name_${cardNameLanguage}`] || item.name_en,
		image: item[`image_${cardNameLanguage}`] || item.image_en
	}))
}

export const getAutoArchetypes = (deckCards, cardNameLanguage, quantity = 3) => {
	const count = {}
	const data = {}

	deckCards.forEach((deckCard) => {
		if (deckCard.place === 'main' && deckCard.card.archetypesData && deckCard.card.archetypesData.length > 0) {
			deckCard.card.archetypesData.forEach((archetype) => {
				if (archetype.deckAssignable && archetype.type !== 'theme') {
					if (count[archetype.id]) {
						count[archetype.id] += 1
					} else {
						count[archetype.id] = 1
						data[archetype.id] = archetype
					}
				}
			})
		}
	})

	return Object.entries(data)
		.filter(([key, value]) => count[key] > 3)
		.sort(([aKey, aValue], [bKey, bValue]) => count[bKey] - count[aKey])
		.map(([key, item]) => ({
			...item,
			name: item[`name_${cardNameLanguage}`] || item.name_en,
			image: item[`image_${cardNameLanguage}`] || item.image_en
		}))
		.slice(0, quantity)
}

const getArchetypeCoverCard = (archetype, cardNameLanguage) => archetype.coverArtworkId ? {
	artwork: {
		name: archetype[`coverCardName_${cardNameLanguage}`] || archetype.coverCardName_en,
		slug: archetype.coverCardSlug,
		image: archetype.image,
		color: archetype.color,
		id: archetype.coverArtworkId
	}
} : {
	card: {
		image_en: archetype.image_en,
		image_de: archetype.image_de,
		slug: archetype.coverCardSlug,
		name_en: archetype.coverCardName_en,
		name_de: archetype.coverCardName_de,
		color: archetype.color
	}
}

export const getAutoCoverCard = (deck, cardNameLanguage, archetypes = null) => {
	const deckArchetypes = archetypes || deck.archetypes

	// archetype cover card
	if (deckArchetypes.length > 0) {
		const archetypeCoverCard = getArchetypeCoverCard(deckArchetypes[0], cardNameLanguage)
		const differentArtwork = archetypeCoverCard.artwork && deck.cards.find(item => item.card.slug === archetypeCoverCard.artwork.slug && (!item.artwork || item.artwork.id !== archetypeCoverCard.artwork.id))

		if (differentArtwork) {
			return differentArtwork
		}

		return archetypeCoverCard
	}

	const autoArchetypes = getAutoArchetypes(deck.cards, cardNameLanguage, 1)

	if (autoArchetypes.length > 0) {
		return getArchetypeCoverCard(autoArchetypes[0], cardNameLanguage)
	}

	// random card
	const mainDeck = deck.cards.filter(item => item.place !== 'note')
	const card = mainDeck[Math.floor(Math.random() * mainDeck.length)]

	if (card) {
		card.artwork = card.artworkId && card.card.alternativeArtworks.find(item => item.id === card.artworkId)

		return card
	}

	return null
}

export const calculateMasterDuelCp = (deck, rarity) => {
	const rarityId = rarity === 'UR' ? 65 : 66

	return deck.cards.reduce((accumulator, current) => {
		if (current.place !== 'side' && current.place !== 'note' && current.card.masterDuelRarity === rarityId) {
			accumulator += 30
		}

		return accumulator
	}, 0)
}

export const getMissingCards = (deckCards) => {
	const missingCount = {}
	const data = {}

	deckCards.filter(item => item.place !== 'note' && !item.ownsCard).forEach((deckCard) => {
		if (!(deckCard.card.slug in missingCount)) {
			missingCount[deckCard.card.slug] = 1
			data[deckCard.card.slug] = deckCard
		} else {
			missingCount[deckCard.card.slug] += 1
		}
	})

	return Object.keys(missingCount).map(slug => ({
		...data[slug],
		missing: missingCount[slug]
	})).sort((a, b) => b.price - a.price)
}
