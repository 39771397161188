const places = {
	1: {
		nameShort: 'general.placeFirst',
		nameList: 'general.placeFirst',
		order: 1
	},
	2: {
		nameShort: 'general.placeSecond',
		nameList: 'general.placeSecond',
		order: 2
	},
	top4: {
		nameShort: 'general.placeTop4',
		nameList: 'general.placeListTop4',
		order: 3
	},
	top8: {
		nameShort: 'general.placeTop8',
		nameList: 'general.placeListTop8',
		order: 5
	},
	top16: {
		nameShort: 'general.placeTop16',
		nameList: 'general.placeListTop16',
		order: 9
	},
	top32: {
		nameShort: 'general.placeTop32',
		nameList: 'general.placeListTop32',
		order: 17
	},
	top64: {
		nameShort: 'general.placeTop64',
		nameList: 'general.placeListTop64',
		order: 33
	},
	top128: {
		nameShort: 'general.placeTop128',
		nameList: 'general.placeListTop128',
		order: 65
	}
}

export default places
