import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import Icon from '../general/Icon'
import { desktop, mobile, VIEWPORT_DESKTOP } from '../../styles/media'
import { isExtraDeckCard, isToken } from '../../helper/card'
import { canAddCard, placeIsFull } from '../../helper/deck'
import { addCard, removeAllCopies, removeLastCard } from '../../state/actionCreators/deckbuilder'
import translate from '../../helper/translate'
import history from '../../helper/history'

const DeckCardActions = ({ addCard, allowed, artworkId, canEdit, card, format, fullscreen, haves, mustBeSubscribed, grid, quantities, row, removeAllCopies, removeLastCard, subscribed, t, viewport }) => {
	const handleOnClick = (event, card, place) => {
		event.preventDefault()
		event.stopPropagation()

		if (mustBeSubscribed) {
			history.push('/pricing', {
				background: history.location
			})

			return
		}

		if (event.shiftKey) {
			removeLastCard(card, place)
			return
		}

		if (canAddCard(format, place, quantities, allowed, subscribed)) {
			addCard(card, place, artworkId, haves)
		} else {
			removeAllCopies(card, place, t)
		}
	}

	const handleOnContextMenu = (event, card, place) => {
		event.preventDefault()
		event.stopPropagation()

		removeLastCard(card, place)
	}

	const mainPlace = isExtraDeckCard(card) ? 'extra' : (card.type === 'skill' ? 'skill' : 'main')
	const mainPlaceAbbr = mainPlace === 'extra' ? 'E' : 'M'
	const token = isToken(card)

	const canAddToOrClearMain = canEdit && card.isPlayable && !token && ((quantities.total < 3 && !placeIsFull(quantities, format, mainPlace, subscribed)) || quantities[mainPlace] !== 0)
	const canAddToOrClearSide = canEdit && card.isPlayable && !token && ((quantities.total < 3 && !placeIsFull(quantities, format, 'side', subscribed)) || quantities.side !== 0)
	const canAddToNote = canEdit && (card.isPlayable || token) && ((quantities.total < 3 && !placeIsFull(quantities, format, 'note', subscribed)) || quantities.note !== 0)

	return (
		<StyledDeckCardActions row={row} fullscreen={fullscreen} grid={grid} disabled={!canEdit}>
			<Action
				onClick={canAddToOrClearMain ? event => handleOnClick(event, card, mainPlace) : undefined}
				onContextMenu={canAddToOrClearMain && viewport === VIEWPORT_DESKTOP ? event => handleOnContextMenu(event, card, mainPlace) : undefined}
				disabled={!canAddToOrClearMain}
				hasCards={quantities[mainPlace] > 0}
				row={row}
			>
				{quantities[mainPlace] > 0 ? <Number row={row}>{quantities[mainPlace]}</Number> : mainPlaceAbbr}
			</Action>
			<Action
				onClick={canAddToOrClearSide ? event => handleOnClick(event, card, 'side') : undefined}
				onContextMenu={canAddToOrClearMain && viewport === VIEWPORT_DESKTOP ? event => handleOnContextMenu(event, card, mainPlace) : undefined}
				disabled={!canAddToOrClearSide}
				hasCards={quantities.side > 0}
				row={row}
			>
				{quantities.side > 0 ? <Number row={row}>{quantities.side}</Number> : 'S'}
			</Action>
			<Action
				onClick={canAddToNote ? event => handleOnClick(event, card, 'note') : undefined}
				onContextMenu={canAddToOrClearMain && viewport === VIEWPORT_DESKTOP ? event => handleOnContextMenu(event, card, mainPlace) : undefined}
				disabled={!canAddToNote}
				hasCards={quantities.note > 0}
				row={row}
			>
				{quantities.note > 0 ? <Number row={row}>{quantities.note}</Number> : <Icon name="bookmark" />}
			</Action>
		</StyledDeckCardActions>
	)
}

DeckCardActions.propTypes = {
	addCard: PropTypes.func.isRequired,
	allowed: PropTypes.number,
	artworkId: PropTypes.number,
	canEdit: PropTypes.bool.isRequired,
	card: PropTypes.object.isRequired,
	format: PropTypes.number,
	fullscreen: PropTypes.bool,
	grid: PropTypes.bool,
	haves: PropTypes.object,
	mustBeSubscribed: PropTypes.bool,
	quantities: PropTypes.object.isRequired,
	removeAllCopies: PropTypes.func.isRequired,
	removeLastCard: PropTypes.func.isRequired,
	row: PropTypes.bool,
	subscribed: PropTypes.string,
	t: PropTypes.func.isRequired,
	viewport: PropTypes.string.isRequired
}

DeckCardActions.defaultProps = {
	allowed: null,
	artworkId: null,
	format: null,
	fullscreen: false,
	grid: false,
	haves: null,
	mustBeSubscribed: false,
	row: false,
	subscribed: null
}

export const StyledDeckCardActions = styled.div`
	display: flex;
	flex-direction: ${props => props.row ? 'row' : 'column'};
	padding: 0.5rem 1rem 0.5rem 0;

	${props => props.row && `
		align-items: center;
		padding: 0;
	`}

	${props => props.fullscreen && !props.grid && `
		padding: 0.5rem 0.5rem 0.5rem 0;
	`}

	${props => props.disabled && `
		opacity: 0.5;
		pointer-events: none;
	`}

	${mobile`
		${props => props.row && `
			gap: 1px;
		`}
	`}
`

export const Action = styled.button`
	background: transparent;
	border: 0;
	color: ${props => props.theme.textLight};
	font-size: 0.8rem;
	font-weight: bold;
	text-align: center;
	padding: 0;
	height: 34px;
	width: 40px;

	${desktop`
		opacity: 0.5;
	`}

	${props => !props.hasCards && `
		&[disabled] {
			color: ${props.theme.textLight};
			opacity: 0.5;
		}
	`}

	${props => props.hasCards && `
		color: #FFF !important;
		opacity: 1 !important;
	`}

	&:first-child {
		border-radius: ${props => props.row ? '4px 0 0 4px' : '4px 4px 0 0'};

		${props => props.hasCards && `
			background: ${props.theme.primary} !important;
		`}

		${desktop`
			&:not([disabled]):hover {
				background: ${props => props.theme.primary} !important;
				color: #FFF !important;
			}
		`}
	}

	${props => props.hasCards && `
		&:nth-child(2) {
			background: ${props.theme.orange} !important;
		}
	`}

	${desktop`
		&:nth-child(2):not([disabled]):hover {
			background: ${props => props.theme.orange} !important;
			color: #FFF !important;
		}
	`}

	${mobile`
		${props => props.row && `
			background: ${props.theme.backgroundLight};
		`}
	`}

	&:last-child {
		border-radius: ${props => props.row ? '0 4px 4px 0' : '0 0 4px 4px'};

		${props => props.hasCards && `
			background: ${props.theme.green} !important;
		`}

		${desktop`
			&:not([disabled]):hover {
				background: ${props => props.theme.green} !important;
				color: #FFF !important;
			}
		`}
	}

	svg {
		height: 14px;
		width: 14px;

		position: relative;
		top: 2px;
	}
`

const Number = styled.span`
	font-family: TechnaSans, ${props => props.theme.fontDefault};
	font-size: 1rem;

	${props => props.row && `
		font-size: 0.9rem;
	`}
`

const enhance = compose(
	connect(state => ({
		subscribed: state.persist.user.subscribed,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		addCard,
		removeAllCopies,
		removeLastCard
	}, dispatch)),
	translate('general')
)

export default enhance(DeckCardActions)
