import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { matchPath } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { forceVisible } from 'react-lazyload'
import loadable from '@loadable/component'
import { getTitle } from '../../helper/deck'
import translate from '../../helper/translate'
import { track } from '../../state/actionCreators/app'
import { mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import { setMode } from '../../state/actions/deckbuilder'
import { scrollToPosition } from '../../helper/scroll'
import { getAppMenuHeight } from '../../helper/device'

class TabDeck extends Component {
	constructor(props) {
		super(props)

		this.state = {
			firstLoaded: false,
			deckComponentLoaded: false
		}

		this.scrollContainer = React.createRef()
	}

	componentDidUpdate(prevProps, prevState) {
		const viewDeck = this.getViewDeckMatch()
		const editDeck = this.getEditDeckMatch()
		const match = viewDeck || editDeck
		const visible = this.isVisible(match)

		if (
			prevProps.location.pathname !== this.props.location.pathname
			|| (prevProps.deck === null && this.props.deck !== null)
			|| (prevProps.deck !== null && this.props.deck !== null && prevProps.deck.slug !== this.props.deck.slug)
			|| prevState.deckComponentLoaded !== this.state.deckComponentLoaded
		) {
			const { authToken, deck, language, setMode, t, track, viewport } = this.props

			if (this.state.deckComponentLoaded && ((match && deck && match.params.slug === deck.slug) || (this.props.location.pathname === '/deck-builder/' && deck))) {
				track(getTitle(deck, language, match ? match.params.modal : null, t), false)
				forceVisible()

				if ((prevProps.deck === null) || (prevProps.deck.slug !== deck.slug)) {
					scrollToPosition(0, this.scrollContainer.current)
				}

				setMode(viewport === VIEWPORT_MOBILE ? 'info' : 'move')
			}
		}

		if (visible && !this.state.firstLoaded) {
			this.setState({
				firstLoaded: true
			})

			this.deckComponent = loadable(() => import('./Deck').then((cmp) => {
				this.setState({
					deckComponentLoaded: true
				})

				return cmp
			}))
		}
	}

	getViewDeckMatch = (props = this.props) => {
		const { location } = props

		const matchCollection = matchPath(location.pathname, {
			path: '/deck/:slug/:navigationTab(prices)/:tab(collection|add)?/:collectionSlug?',
			exact: true
		})

		const matchDeck = matchPath(location.pathname, {
			path: '/deck/:slug/:navigationTab(prices|test|comment)?/:commentId?',
			exact: true
		})

		return matchCollection || matchDeck
	}

	getEditDeckMatch = (props = this.props) => {
		const { location } = props

		const matchCollection = matchPath(location.pathname, {
			path: '/deck-builder/:slug/:tab(collection|add)?/:collectionSlug?',
			exact: true
		})

		const matchDeck = matchPath(location.pathname, {
			path: '/deck-builder/:slug/:modal?',
			exact: true
		})

		return matchCollection || matchDeck
	}

	isVisible = (match, props = this.props) => {
		const { deck, location } = props

		return !!((match && deck && deck.slug === match.params.slug) || (location.pathname === '/deck-builder/' && deck))
	}

	render() {
		const { location, viewport } = this.props
		const { firstLoaded } = this.state

		const viewDeck = this.getViewDeckMatch()
		const editDeck = this.getEditDeckMatch()
		const match = viewDeck || editDeck
		const visible = this.isVisible(match)

		const DeckCmp = this.deckComponent

		return (
			<Wrapper
				animate={editDeck && viewport === VIEWPORT_DESKTOP}
				show={visible}
				viewDeck={viewDeck}
				id="tabDeckWrapper"
				ref={this.scrollContainer}
			>
				{firstLoaded && (
					<DeckCmp
						commentId={match && match.params.commentId ? Number(match.params.commentId) : null}
						location={location}
						match={match}
						visible={visible}
					/>
				)}
			</Wrapper>
		)
	}
}

TabDeck.propTypes = {
	authToken: PropTypes.string,
	deck: PropTypes.object,
	language: PropTypes.string.isRequired,
	location: PropTypes.object.isRequired,
	setMode: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	track: PropTypes.func.isRequired,
	viewport: PropTypes.string.isRequired
}

TabDeck.defaultProps = {
	authToken: null,
	deck: null
}

const Wrapper = styled.div`
	${props => props.animate ? `
		opacity: 0;
		transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1), transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1), z-index 0.1s 0.2s;
		transform: scale(0.85);
		z-index: -1;

		${props.show && `
			opacity: 1;
			transform: scale(1);
			transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1), transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1);
			z-index: 1;
		`}
	` : `
		display: ${props.show ? 'block' : 'none'};
	`}


	${mobile`
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		position: fixed;

		top: 44px;
		left: 0;
		bottom: ${getAppMenuHeight()};

		width: 100%;
	`}
`

const enhance = compose(
	translate('general'),
	connect(state => ({
		authToken: state.persist.authToken,
		deck: state.deckbuilder.deck,
		language: state.persist.language,
		user: state.persist.user,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		setMode,
		track
	}, dispatch))
)

export default enhance(TabDeck)
