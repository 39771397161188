import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Spinner, { StyledSpinner } from '../general/Spinner'
import { desktop, mobile } from '../../styles/media'
import Icon, { StyledIcon } from '../general/Icon'

const Button = React.forwardRef(({ children, disabled, focused, green, hidden, loading, onClick, red, secondary, small, submit, success }, ref) => (
	<StyledButton
		disabled={disabled}
		focused={focused}
		green={green}
		loading={loading ? 1 : 0}
		hidden={hidden}
		onClick={onClick}
		red={red}
		ref={ref}
		secondary={secondary}
		small={small}
		success={success || undefined}
		type={submit ? 'submit' : 'button'}
		tabIndex="0"
	>
		{loading && <Spinner />}
		<SuccessIcon success={success} small={small}><Icon name="check" /></SuccessIcon>
		<span>
			{children}
		</span>
	</StyledButton>
))

Button.propTypes = {
	children: PropTypes.any,
	disabled: PropTypes.bool,
	focused: PropTypes.bool,
	green: PropTypes.bool,
	hidden: PropTypes.bool,
	loading: PropTypes.bool,
	onClick: PropTypes.func,
	red: PropTypes.bool,
	secondary: PropTypes.bool,
	small: PropTypes.bool,
	submit: PropTypes.bool,
	success: PropTypes.bool
}

Button.defaultProps = {
	children: '',
	disabled: false,
	focused: false,
	green: false,
	hidden: false,
	loading: false,
	onClick: () => {},
	red: false,
	secondary: false,
	small: false,
	submit: false,
	success: false
}

const getBackgroundColor = (props, hover = false) => {
	let key = 'primary'

	if (props.red) {
		key = 'red'
	} else if (props.green || props.success) {
		key = 'green'
	} else if (props.secondary) {
		key = 'secondary'
	}

	if (hover) {
		key += 'Hover'
	}

	return props.theme[key]
}

export const StyledButton = styled.button`
	background-color: ${props => getBackgroundColor(props)};
	border: none;
	border-radius: 25px;
	color: #FFF;
	cursor: ${props => (props.loading ? 'default' : 'pointer')};
	display: ${props => props.hidden ? 'none' : 'inline-block'};
	font-size: ${props => props.small ? '0.8rem' : '1rem'};
	padding: ${props => (props.small ? '0.35rem 0.75rem' : '0.75rem 1.5rem')};
	position: relative;
	transition: all 0.2s ease-in-out;
	width: fit-content;

	${desktop`
		&:hover {
			${props => !props.loading && !props.disabled && !props.success && `
				background-color: ${getBackgroundColor(props, true)};
				${!props.small ? 'transform: scale(1.05);' : ''}
			`}
		}

		${props => props.focused && `
			${!props.loading && !props.disabled && !props.success && `
				background-color: ${getBackgroundColor(props, true)};
				${!props.small ? 'transform: scale(1.05);' : ''}
			`}
			${(!props.loading && !props.disabled) && `
				color: ${props.theme.textInverted};
			`}
		`}
	`}

	&:active {
		${mobile`
			transform: scale(0.95);
		`}
	}

	span {
		align-items: center;
		display: inline-flex;
		visibility: ${props => (props.loading || props.success ? 'hidden' : 'visible')};

		svg {
			margin-right: 0.5rem;
		}
	}

	& + button, & + a > button {
		margin-left: 1rem;
	}

	${props => props.disabled && `
		cursor: not-allowed;
		opacity: 0.7;
	`}

	${props => props.success && `
		cursor: default;
		pointer-events: none;
	`}

	${StyledSpinner} {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		height: 100%;

		> div {
			height: 10px;
			width: 10px;
		}
	}
`

const SuccessIcon = styled.div`
	opacity: ${props => props.success ? 1 : 0};
	transition: opacity 0.1s;
	z-index: ${props => props.success ? 1 : -1};

	${StyledIcon} {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		color: #FFF;
		height: 100%;

		${props => props.small && `
			height: 16px;
			width: 16px;
		`}
	}
`

export default Button
