import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import types from '../../data/types'
import { translationsMapping } from '../../../config'
import cardTypes from '../../data/cardTypes'
import monsterTypes from '../../data/monsterTypes'
import translate from '../../helper/translate'
import monsterAttributes from '../../data/monsterAttributes'

const FilterToString = ({ filter, language, t }) => {
	const languageCode = translationsMapping[language]

	let str = ''

	const getTypeStr = (type) => {
		if (type === 'monster' && filter.monsterAttribute) {
			if (Array.isArray(filter.monsterAttribute)) {
				filter.monsterAttribute.forEach((monsterAttribute, index) => {
					str += monsterAttributes[monsterAttribute][`name_${languageCode}`].toUpperCase()

					if (languageCode === 'de') {
						str += '-'
					}

					if (index === filter.monsterAttribute.length - 2) {
						str += ` ${t('general.or')} `
					} else if (index + 1 !== filter.monsterAttribute.length) {
						str += ', '
					}
				})
			} else {
				str += monsterAttributes[filter.monsterAttribute][`name_${languageCode}`].toUpperCase()

				if (languageCode === 'de') {
					str += '-'
				}
			}

			if (languageCode === 'en') {
				str += ' '
			}
		}

		if (type === 'monster' && filter.monsterType) {
			if (Array.isArray(filter.monsterType)) {
				filter.monsterType.forEach((monsterType, index) => {
					str += monsterTypes[monsterType][`name_${languageCode}`]

					if (languageCode === 'de') {
						str += '-'
					}

					if (index === filter.monsterType.length - 2) {
						str += ` ${t('general.or')} `
					} else if (index + 1 !== filter.monsterType.length) {
						str += ', '
					}
				})
			} else {
				str += monsterTypes[filter.monsterType][`name_${languageCode}`]

				if (languageCode === 'de') {
					str += '-'
				} else {
					str += ' '
				}
			}

			if (languageCode === 'en') {
				str += ' '
			}
		}

		let setToLowerCase = false

		if (filter.cardTypes) {
			const typeCardType = filter.cardTypes.filter(item => cardTypes[item].type === type)

			typeCardType.forEach((cardType, index) => {
				str += cardTypes[cardType][`name_${languageCode}`]

				const cardTypeId = Number(cardType)

				if (languageCode === 'de') {
					if (cardTypeId === 15 || cardTypeId === 16 || cardTypeId === 21 || cardTypeId === 22) {
						str += 'e '
					} else if (cardTypes[cardType].type === 'spell' || cardTypes[cardType].type === 'trap') {
						str += ''
						setToLowerCase = true
					} else {
						str += '-'
					}
				} else {
					str += ' '
				}

				if (index + 1 !== typeCardType.length) {
					str += ', '
				}
			})
		}

		if (type) {
			const typeName = t(types.find(item => item.value === type).namePlural)

			if (setToLowerCase) {
				str += typeName.toLowerCase()
			} else {
				str += typeName
			}
		}
	}

	if (Array.isArray(filter.type)) {
		const types = filter.type.sort((a, b) => {
			if (a === 'monster' && b === 'spell') return -1
			if (a === 'spell' && b === 'monster') return 1
			if (a === 'monster' && b === 'trap') return -1
			if (a === 'trap' && b === 'monster') return 1
			if (a === 'spell' && b === 'trap') return -1
			if (a === 'trap' && b === 'spell') return 1

			return 0
		})

		if (filter.cardTypes) {
			filter.cardTypes.filter(item => !types.find(item2 => item2 === cardTypes[item].type)).forEach((cardType) => {
				const { type } = cardTypes[cardType]

				getTypeStr(type)

				str += ' / '
			})
		}

		types.forEach((type, index) => {
			getTypeStr(type)

			if (index + 1 !== filter.type.length) {
				str += ' / '
			}
		})
	} else {
		getTypeStr(filter.type)
	}

	return str
}

FilterToString.propTypes = {
	filter: PropTypes.object.isRequired,
	language: PropTypes.string.isRequired,
	t: PropTypes.func.isRequired
}

const enhance = compose(
	connect(state => ({
		language: state.persist.language
	})),
	translate('general')
)

export default enhance(FilterToString)
