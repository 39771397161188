import React from 'react'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ProTag from '../../components/general/ProTag'
import AccountNotifications from './AccountNotifications'
import { slugifyUsername } from '../../helper/string'
import Icon from '../../components/general/Icon'
import Separator from '../../components/button/Separator'
import { desktop, isStandalone, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import references from '../../data/references'
import { install } from '../../helper/pwa'
import DropDown, { DropDownLink } from '../../components/button/DropDown'
import { logout } from '../../state/actionCreators/app'
import { setReference } from '../../state/actions/app'
import translate from '../../helper/translate'
import Avatar from '../../components/user/Avatar'
import { getHelpLink } from '../../helper/links'

const UserDropDown = ({ authToken, avatar, button, logout, pwaInstallEvent, role, setReference, subscribed, t, title, userLanguage, username, viewport }) => (
	<DropDown
		button={button}
		top={50}
		right={9}
		width={240}
	>
		{onBeforeNavigate => (
			<>
				{authToken ? (
					<>
						<StyledDropDownLink
							avatar={viewport === VIEWPORT_MOBILE}
							to={`/user/${slugifyUsername(username)}`}
							onBeforeNavigate={onBeforeNavigate}
						>
							{viewport === VIEWPORT_DESKTOP ? (
								<>
									<Icon name="user" />
									<span>{t('general.myProfile')}</span>
								</>
							) : (
								<>
									<Avatar width={40} avatar={avatar || 'avatar-male'} />
									<span>{username}</span>
									{subscribed === 'pro' && <ProTag title={title} />}
								</>
							)}
						</StyledDropDownLink>
						<StyledDropDownLink to="/settings" onBeforeNavigate={onBeforeNavigate}>
							<Icon name="cog" />
							<span>{t('general.settings')}</span>
							<AccountNotifications type="settings" />
						</StyledDropDownLink>
						{role === 'admin' || role === 'mod' ? (
							<StyledDropDownLink to="/admin" onBeforeNavigate={onBeforeNavigate}>
								<Icon name="terminal" />
								<span>{t('general.admin')}</span>
								<AccountNotifications type="admin" />
							</StyledDropDownLink>
						) : null}

						{viewport === VIEWPORT_DESKTOP && (
							<>
								<Separator />

								{!subscribed && (
									<>
										<StyledDropDownLink
											to="/product/collection-manager"
											onBeforeNavigate={() => {
												onBeforeNavigate()
												setReference(references.USER_MENU)
											}}
										>
											<Icon name="archive" />
											<span>{t('general.collectionManager')}</span>
										</StyledDropDownLink>
										<StyledDropDownLink
											highlight
											to="/pricing"
											onBeforeNavigate={() => {
												onBeforeNavigate()
												setReference(references.USER_MENU)
											}}
										>
											<Icon name="star" />
											<span>cardcluster <ProTag /></span>
										</StyledDropDownLink>
										<Separator />
									</>
								)}

								{!isStandalone() && (
									<StyledDropDownLink to={!pwaInstallEvent ? '/install' : null} onClick={pwaInstallEvent ? () => install(pwaInstallEvent) : undefined} onBeforeNavigate={onBeforeNavigate}>
										<Icon name="device-mobile" />
										<span>{t('general.installApp')}</span>
									</StyledDropDownLink>
								)}
								<StyledDropDownLink external externalNoIcon to={getHelpLink(userLanguage)} onBeforeNavigate={onBeforeNavigate}>
									<Icon name="book-open" />
									<span>{t('general.help')}</span>
								</StyledDropDownLink>
								<StyledDropDownLink to="/support" onBeforeNavigate={onBeforeNavigate}>
									<Icon name="support" />
									<span>{t('general.support')}</span>
								</StyledDropDownLink>
							</>
						)}
					</>
				) : (
					<>
						<StyledDropDownLink
							to="/signup"
							onBeforeNavigate={onBeforeNavigate}
						>
							<Icon name="user-add" />
							<span>{t('general.register')}</span>
						</StyledDropDownLink>
						<StyledDropDownLink
							to="/login"
							onBeforeNavigate={onBeforeNavigate}
						>
							<Icon name="key" />
							<span>{t('general.login')}</span>
						</StyledDropDownLink>
					</>
				)}

				{authToken && (
					<>
						<Separator />
						<StyledDropDownLink red onClick={() => logout(t, false)} onBeforeNavigate={onBeforeNavigate}>
							<Icon name="logout" />
							<span>{t('general.logout')}</span>
						</StyledDropDownLink>
					</>
				)}
			</>
		)}
	</DropDown>
)

UserDropDown.propTypes = {
	authToken: PropTypes.string,
	avatar: PropTypes.string,
	button: PropTypes.any.isRequired,
	logout: PropTypes.func.isRequired,
	pwaInstallEvent: PropTypes.any,
	role: PropTypes.string.isRequired,
	setReference: PropTypes.func.isRequired,
	subscribed: PropTypes.string,
	t: PropTypes.func.isRequired,
	title: PropTypes.string,
	userLanguage: PropTypes.string.isRequired,
	username: PropTypes.string.isRequired,
	viewport: PropTypes.string.isRequired
}

UserDropDown.defaultProps = {
	authToken: null,
	avatar: null,
	pwaInstallEvent: null,
	subscribed: null,
	title: null
}

const StyledDropDownLink = styled(DropDownLink)`
	${desktop`
		svg {
			margin-left: 0.25rem;
		}
	`}
`

const enhance = compose(
	connect(state => ({
		authToken: state.persist.authToken,
		avatar: state.persist.user.avatar,
		pwaInstallEvent: state.app.pwaInstallEvent,
		role: state.persist.user.role,
		subscribed: state.persist.user.subscribed,
		title: state.persist.user.title,
		userLanguage: state.persist.language,
		username: state.persist.user.username,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		logout,
		setReference
	}, dispatch)),
	translate('general'),
	withRouter
)

export default enhance(UserDropDown)
