import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import BetterButton, { StyledBetterButton } from '../../components/button/BetterButton'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../../styles/media'
import Content from '../../components/layout/Content'
import translate from '../../helper/translate'

const HomeJumbotron = ({ t, viewport }) => {
	const ContentCmp = viewport === VIEWPORT_MOBILE ? 'div' : Content

	return (
		<ContentCmp>
			<WelcomeHeader>
				<WelcomeHeaderHeading>
					{t('general.homeJumbotronHeading1')}{viewport === VIEWPORT_MOBILE ? <br /> : <>&nbsp;</>}{t('general.homeJumbotronHeading2')}
				</WelcomeHeaderHeading>
				<WelcomeHeaderSubline>
					{t('general.homeJumbotronSubline')}
				</WelcomeHeaderSubline>

				<WelcomeButtons>
					<BetterButton to="/signup" small={viewport === VIEWPORT_MOBILE}>
						{t('general.homeJumbotronCta')}
					</BetterButton>
					<BetterButton to="/product/collection-manager" small={viewport === VIEWPORT_MOBILE}>
						{t('general.homeJumbotronSecondary')}
					</BetterButton>
				</WelcomeButtons>
			</WelcomeHeader>
		</ContentCmp>
	)
}

HomeJumbotron.propTypes = {
	t: PropTypes.func.isRequired,
	viewport: PropTypes.string.isRequired
}

const WelcomeHeader = styled.div`
	color: ${props => props.theme.white};
	padding: 1.5rem 0 calc(30px + 1rem) !important;
	width: 100%;

	align-items: flex-start;
	display: flex;
	flex-direction: column;

	p {
		color: rgba(255, 255, 255, 0.8);
		font-size: 1.1rem;
		font-weight: 500;
		margin: 0;
	}

	${mobile`
		background: linear-gradient(#2a4859, #20355b);
		padding: 1.5rem 2rem 2rem !important;

		align-items: center;
		text-align: center;
	`}
`

const WelcomeHeaderHeading = styled.div`
	font-family: TechnaSans, ${props => props.theme.fontDefault}, sans-serif;
	font-size: 2rem;
	margin-top: 1rem;

	${mobile`
		font-size: 1.5rem;
		margin-top: 0;
	`}
`

const WelcomeHeaderSubline = styled.div`
	color: rgba(255, 255, 255, 0.8);
	font-family: TechnaSans, ${props => props.theme.fontDefault}, sans-serif;
	font-size: 1.5rem;
	margin-top: 1rem;

	${mobile`
		font-size: 1.2rem;
	`}
`

const WelcomeButtons = styled.div`
	display: flex;
	gap: 1.5rem;
	margin-top: 1.5rem;

	${StyledBetterButton} {
		width: fit-content;
	}

	> :last-child {
		background: rgba(255, 255, 255, 0.1);
	}

	${desktop`
		> :last-child:hover {
			background: rgba(255, 255, 255, 0.2);
		}
	`}

	${mobile`
		align-items: center;
		flex-direction: column;
		gap: 1rem;
	`}
`

const enhance = compose(
	connect(state => ({
		viewport: state.app.viewport
	})),
	translate('general')
)

export default enhance(HomeJumbotron)
