import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { TCG } from '../../data/formats'
import FormatName from '../ygo/FormatName'
import PlayerName from '../card/name/PlayerName'
import ProTag from '../general/ProTag'
import { month } from '../../helper/date'
import { VIEWPORT_MOBILE } from '../../styles/media'
import EventName from '../card/name/EventName'
import RelativeTime from './RelativeTime'
import DeckLinkCommunity from './DeckLinkCommunity'
import TagOld from '../general/TagOld'
import Name from '../card/name/Name'
import translate from '../../helper/translate'
import { getSeasonName } from '../../helper/card'
import Avatar from '../user/Avatar'

const DeckMeta = ({ browsePublicDecks, canEdit, deck, deckPage, hideCommunity, isResult, profile, showAddedToFavoritesAt, showArchivedAt, showCreatedAt, showEventDate, t, viewport }) => {
	let date = deck.updatedAt

	if (showCreatedAt) {
		date = deck.createdAt
	} else if (showArchivedAt) {
		date = deck.archived
	} else if (showAddedToFavoritesAt) {
		date = deck.userStar
	}

	return (
		<StyledDeckMeta>
			<Author>
				{!deck.hasResult && ((canEdit && !browsePublicDecks) || profile || deckPage || deck.format !== TCG) && (
					<Format>
						{deck.format && <FormatName abbr format={deck.format} />}
						{(!browsePublicDecks || profile) && deck.season && <>&nbsp;{getSeasonName(deck.season)}</>}
					</Format>
				)}

				{!showEventDate && deck.hasResult && (
					<PlayerName
						country={deck.eventPlayers[0].playerCountry}
						name={deck.eventPlayers[0].playerName}
						username={deck.eventPlayers[0].playerUsername}
					/>
				)}

				{!deck.hasResult && (!canEdit || browsePublicDecks) && !profile && (
					<>
						{deck.format !== TCG && (
							<>
								&nbsp;&middot;&nbsp;
							</>
						)}
						{deck.user.subscribed && (
							<Avatar width={16} avatar={deck.user.avatar} />
						)}
						{deck.user.username}
						{deck.user.subscribed === 'pro' && <ProTag title={deck.user.title} />}
					</>
				)}

				{deck.hasResult && !isResult && (
					<>
						<Time>
							{!showEventDate && <>&nbsp;&middot;&nbsp;</>}

							{month(deck.eventPlayers[0].event.date)}

							{showEventDate && viewport === VIEWPORT_MOBILE && (
								<>
									&nbsp;&middot;&nbsp;
									<EventName
										name={deck.eventPlayers[0].event.name}
									/>
								</>
							)}
						</Time>
					</>
				)}

				{!deck.hasResult && (
					<>
						&nbsp;
						<Time>
							&middot;&nbsp;
							{showAddedToFavoritesAt && `${t('general.starredAt')} `}
							{showArchivedAt && `${t('general.archived')} `}
							<RelativeTime date={date} />
						</Time>
					</>
				)}
			</Author>
			{deck.visibility === 'public' && !hideCommunity && !deckPage && (
				<DeckLinkCommunity deck={deck} />
			)}
		</StyledDeckMeta>
	)
}

DeckMeta.propTypes = {
	browsePublicDecks: PropTypes.bool,
	canEdit: PropTypes.bool,
	deck: PropTypes.object.isRequired,
	deckPage: PropTypes.bool,
	hideCommunity: PropTypes.bool,
	isResult: PropTypes.bool,
	profile: PropTypes.bool,
	showAddedToFavoritesAt: PropTypes.bool,
	showArchivedAt: PropTypes.bool,
	showCreatedAt: PropTypes.bool,
	showEventDate: PropTypes.bool,
	t: PropTypes.func.isRequired,
	viewport: PropTypes.string.isRequired
}

DeckMeta.defaultProps = {
	browsePublicDecks: false,
	canEdit: false,
	deckPage: false,
	hideCommunity: false,
	isResult: false,
	profile: false,
	showAddedToFavoritesAt: false,
	showArchivedAt: false,
	showCreatedAt: false,
	showEventDate: false
}

const StyledDeckMeta = styled.div`
	display: flex;
	margin-top: 0.15rem;
`

const Format = styled.span`
	font-size: 0.8rem;
`

export const Author = styled.div`
	color: ${props => props.theme.textLight};
	font-size: 0.9rem;
	font-weight: 400;

    align-items: flex-end;
	display: flex;
    width: fit-content;

    white-space: nowrap;

	img, svg {
		border-radius: 50%;
		height: 16px;
		width: 16px;
		margin-right: 0.25rem;
	}

	${TagOld} {
		font-size: 0.8em;
		margin-left: 0.25rem;
	}

	${Name} {
		display: inline-flex;

		img {
			border-radius: 1px;
			height: 12px;
			width: 12px;
		}
	}
`

export const Time = styled.span`
	color: ${props => props.theme.textVeryLight};
	font-size: 0.8rem;
`

const enhance = compose(
	connect(state => ({
		viewport: state.app.viewport
	})),
	translate('general')
)

export default enhance(DeckMeta)
