import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import EmptySquare from '../../../images/emptySquare.svg'
import Icon from '../../../components/general/Icon'
import UnsavedChanges from '../../../components/deck/UnsavedChanges'
import Visibility from '../../../components/deck/Visibility'
import { getContrastColor, hexToRgb } from '../../../helper/color'
import { mobile } from '../../../styles/media'

const CoverImage = ({ backgroundColor, card, cardNameLanguage, dark, deck, dropdown, hideNotPlayable, ignoreUserSubscribed, large, selected, small }) => {
	const coverCard = card || deck.coverArtwork || deck.coverCard
	const color = coverCard?.color
	const image = coverCard ? coverCard.image || coverCard[`image_${cardNameLanguage}`] || coverCard.image_en : null

	return (
		<StyledCoverImage
			backgroundColor={backgroundColor}
			color={color}
			dark={dark}
			large={large}
			small={small}
			dropdown={dropdown}
			notPlayable={!hideNotPlayable && !card && deck.legalSince === null}
			hasImage={ignoreUserSubscribed || deck.user.subscribed}
		>
			{ignoreUserSubscribed || deck.user.subscribed ? (
				<img
					src={image ? `${image}-crop-${large ? 50 : 30}.webp` : EmptySquare}
					srcSet={image ? `${image}-crop-${large ? 50 : 30}.webp 1x, ${image}-crop-${large ? 100 : 60}.webp 2x, ${image}-crop-${large ? 150 : 90}.webp 3x` : null}
					alt={coverCard ? coverCard.name : ''}
				/>
			) : (
				<Icon name="deck" />
			)}
			{!card && deck.visibility && deck.visibility !== 'public' && <Visibility value={deck.visibility} color={coverCard ? coverCard.color : null} large={large} />}
			{!card && <UnsavedChanges visible={deck.saved === false} />}
			{card && <Selected visible={selected}><Icon name="check" /></Selected>}
			{dropdown && <EditIndicator><Icon name="pencil" /></EditIndicator>}
		</StyledCoverImage>
	)
}

CoverImage.propTypes = {
	backgroundColor: PropTypes.string,
	card: PropTypes.object,
	cardNameLanguage: PropTypes.string,
	dark: PropTypes.bool,
	deck: PropTypes.object,
	dropdown: PropTypes.bool,
	hideNotPlayable: PropTypes.bool,
	ignoreUserSubscribed: PropTypes.bool,
	large: PropTypes.bool,
	small: PropTypes.bool,
	selected: PropTypes.bool
}

CoverImage.defaultProps = {
	backgroundColor: null,
	cardNameLanguage: null,
	card: null,
	dark: false,
	deck: null,
	dropdown: false,
	hideNotPlayable: false,
	ignoreUserSubscribed: false,
	large: false,
	small: false,
	selected: false
}

export const EditIndicator = styled.div`
	position: absolute;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;

	align-items: center;
	display: flex;
	justify-content: center;

	color: rgba(255, 255, 255, 0.95);
	opacity: 0;
	transition: 0.1s;
`

export const StyledCoverImage = styled.div`
	border-radius: 15px;
	position: relative;
	transition: box-shadow 0.1s;

	${props => props.large && `
		border-radius: 25px;
	`}

	${props => props.small && `
		border-radius: 10px;
	`}

	${props => !props.hasImage ? '&' : 'img'} {
		border-radius: 15px;
		height: 30px;
		width: 30px;
		min-width: 30px;

		${props => props.large && `
			border-radius: 25px;
			height: 50px;
			width: 50px;
			min-width: 50px;
		`}

		${props => props.small && `
			border-radius: 10px;
			height: 20px;
			width: 20px;
			min-width: 20px;
		`}

		${props => !props.notPlayable ? `
			box-shadow: 0 0 0 1px ${props.backgroundColor || props.theme.background}, 0 0 0 ${props.small ? '2px' : '3px'} ${props.color ? props.color : 'transparent'};
		` : `
			box-shadow: 0 0 0 1px ${props.backgroundColor || props.theme.background}, 0 0 0 ${props.small ? '2px' : '3px'} ${props.dark ? '#555' : props.theme.backgroundConcrete};
		`}
	}

	${props => !props.hasImage && `
		background: ${props.color || props.theme.secondary};
		border-radius: 15px;

		align-items: center;
		justify-content: center;
		display: flex;

		> svg {
			color: ${getContrastColor(props.color, 0.3)};

			${!props.large && !props.small && `
				height: 16px;
				width: 16px;
			`}

			${props.small && `
				height: 10px;
				width: 10px;
			`}
		}
	`}

	${props => !props.hasImage && `
		&, img {
			border-radius: 6px;
			box-shadow: none;
		}
	`}

	${UnsavedChanges} {
		box-shadow: 0 0 0 ${props => props.large ? '4px' : '2px'} ${props => props.backgroundColor || props.theme.background};

		position: absolute;
		top: -2px;
		right: -2px;
		z-index: 2;

		${props => props.large && `
			height: 14px;
			width: 14px;
		`}
	}

	${props => props.notPlayable && `
		opacity: 0.7;

		img {
			filter: grayscale(100%);
			-webkit-transform: translateZ(0);
			-webkit-perspective: 1000;
			-webkit-backface-visibility: hidden;
		}

		${!props.hasImage && `
			background: ${props.dark ? '#555' : props.theme.backgroundConcrete};

			svg {
				color: ${props.theme.textVeryLight};
			}
		`}
	`}

	${mobile`
		${props => !props.hasImage ? '&' : 'img'} {
			${props => props.large && `
				height: 40px;
				width: 40px;
				min-width: 40px;
			`}

			${props => props.large && props.hasImage && `
				border-radius: 20px;
			`}
		}
	`}
`

const Selected = styled.div`
	background: ${props => props.theme.primary};
	border-radius: 50%;

	transition: opacity 250ms cubic-bezier(0, 1, 0.4, 1), transform 250ms cubic-bezier(0.18, 1.25, 0.4, 1);
	opacity: ${props => props.visible ? 1 : 0};
	transform: ${props => props.visible ? 'scale(1)' : 'scale(0.85)'};

	margin-left: 0.35rem;
	height: 20px;
	width: 20px;

	position: absolute;
	top: -2px;
	right: -2px;
	z-index: 2;

	align-items: center;
	display: flex;
	justify-content: center;

	svg {
		color: ${props => props.theme.white};
		height: 12px;
		width: 12px;
	}

	${mobile`
		height: 16px;
		width: 16px;

		svg {
			height: 10px;
			width: 10px;
		}
	`}
`

const enhance = connect(state => ({
	cardNameLanguage: state.persist.user.settings['app.cardNameLanguage']
}))

export default enhance(CoverImage)
