import YgoLogo from '../images/games/ygo.svg'

export const YGO = 1

const games = {
	[YGO]: {
		name: 'Yu-Gi-Oh!',
		slug: 'ygo',
		image: YgoLogo
	}
}

export default games
