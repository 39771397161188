import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../styles/media'
import translate from '../helper/translate'
import Icon from '../components/general/Icon'
import { discordLink, translationsMapping } from '../../config'
import LogoImage from '../images/logo/cardcluster-white.svg'
import Link from '../components/router/Link'
import references from '../data/references'
import { setReference } from '../state/actions/app'
import BetterButton, { StyledBetterButton } from '../components/button/BetterButton'
import { getHelpLink } from '../helper/links'

const Footer = ({ language, onBeforeNavigate, setReference, t, theme, viewport }) => {
	const menu = (
		<MenuColumns>
			<Menu>
				<Heading>{t('general.products')}</Heading>
				{[
					{
						name: 'general.collectionManager',
						to: '/product/collection-manager'
					},
					{
						name: 'general.cardclusterPro',
						to: '/pricing'
					},
					{
						name: 'general.scans',
						to: '/partner/card-images'
					}
				].filter(item => translationsMapping[language] === 'de' || item.to !== '/partner/card-images').map(item => (
					<Link
						to={item.to}
						onBeforeNavigate={() => {
							setReference(references.FOOTER)
							if (onBeforeNavigate) {
								onBeforeNavigate()
							}
						}}
						key={item.to}
					>
						{t(item.name)}
					</Link>
				))}

				{viewport === VIEWPORT_DESKTOP && (
					<MenuLinkPlaceholder />
				)}
				<br />

				<Heading>{t('general.support')}</Heading>
				{[
					{
						name: 'general.help',
						icon: 'book-open'
					},
					{
						name: 'general.contactLink',
						to: '/support',
						icon: 'support'
					},
					{
						name: 'general.installApp',
						to: '/install',
						icon: 'device-mobile'
					}
				].map(item => (
					<Link
						to={item.name === 'general.help' ? getHelpLink(language) : item.to}
						external={item.name === 'general.help'}
						externalNoIcon={item.name === 'general.help'}
						onBeforeNavigate={onBeforeNavigate}
						key={item.name}
					>
						{t(item.name)}
					</Link>
				))}
			</Menu>
			<Menu>
				<Heading>{t('general.community')}</Heading>
				{[
					{
						name: 'general.changelog',
						to: '/changelog'
					},
					{
						name: 'general.roadmap',
						to: '/roadmap'
					},
					{
						name: 'general.discord',
						to: discordLink,
						external: true
					}
				].map(item => (
					<Link to={item.to} onBeforeNavigate={onBeforeNavigate} external={item.external} key={item.to}>
						{t(item.name)}
					</Link>
				))}

				<br />

				<Heading>{t('general.company')}</Heading>
				{[
					{
						name: 'general.about',
						to: '/about'
					},
					{
						name: 'general.imprint',
						to: '/legal'
					},
					{
						name: 'general.privacy',
						to: '/privacy'
					},
					{
						name: 'general.terms',
						to: '/terms'
					},
				].map(item => (
					<Link to={item.to} onBeforeNavigate={onBeforeNavigate} key={item.to}>
						{t(item.name)}
					</Link>
				))}
			</Menu>
		</MenuColumns>
	)

	return (
		<StyledFooter>
			<Top>
				<Left>
					<Text>
						<p>
							<Logo><img src={LogoImage} alt="cardcluster Logo" /></Logo>
						</p>
						<p>
							{t('general.slogan', { cardcluster: <strong>cardcluster</strong> })}
						</p>

						<p>
							{t('general.footerSignUpText', { signUpLink: <Link to="/signup">{t('general.footerSignUpText2')}</Link> })}
						</p>

						<Social>
							<List>
								<a href={`https://twitter.com/cardcluster${translationsMapping[language] === 'de' ? 'DE' : ''}`}>
									<Icon name="social-twitter" />
								</a>
								<a href="https://www.instagram.com/cardcluster">
									<Icon name="social-instagram" />
								</a>
								<a href={discordLink}>
									<Icon name="social-discord" />
								</a>
							</List>
						</Social>

						<Feedback>
							<p>{t('general.footerFeedback')}</p>
							<BetterButton
								to="/support/feedback"
								onBeforeNavigate={onBeforeNavigate}
								color={theme.secondary}
								small={viewport === VIEWPORT_MOBILE}
							>
								{t('general.giveFeedback')}
							</BetterButton>
						</Feedback>

						{viewport === VIEWPORT_MOBILE && menu}

						<Disclaimer>
							<p><b>{t('general.footerLinkDisclaimer')}</b></p>

							<p>{t('general.footerLinkDisclaimerText')}</p>
						</Disclaimer>
					</Text>
				</Left>
				{viewport === VIEWPORT_DESKTOP && (
					<Right>
						{menu}
					</Right>
				)}
			</Top>

			<Copyright>
				&copy; {new Date().getFullYear()} cardcluster
			</Copyright>
		</StyledFooter>
	)
}

Footer.propTypes = {
	language: PropTypes.string.isRequired,
	onBeforeNavigate: PropTypes.func,
	setReference: PropTypes.func.isRequired,
	t: PropTypes.func.isRequired,
	theme: PropTypes.object.isRequired,
	viewport: PropTypes.string.isRequired
}

Footer.defaultProps = {
	onBeforeNavigate: undefined
}

const Feedback = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	gap: 4rem;

	${StyledBetterButton} {
		width: fit-content;
	}

	p {
		color: rgba(255, 255, 255, 0.7);
		font-size: 1.1rem;
		font-weight: 500;
		margin: 0;
	}

	${desktop`
		border: 2px solid #444;
		border-radius: 20px;
		padding: 1rem 1.5rem;

		${StyledBetterButton} {
			width: 300px;
		}
	`}

	${mobile`
		background: rgba(255, 255, 255, 0.07);
        padding: 1.5rem 2rem;
        margin-left: -30px;
        width: calc(100% + 60px);

		align-items: flex-start;
		flex-direction: column;
		gap: 1.5rem;
		margin-bottom: 2rem;
		padding: 1.5rem 2rem;

		p, ${StyledBetterButton} {
			font-size: 0.9rem;
		}
	`}
`

const StyledFooter = styled.footer`
	background: ${props => props.theme.header};
	display: flex;
	flex-direction: column;
	padding: 4rem;

	${desktop`
		align-items: center;
		justify-content: center;
		margin-top: 5rem;
	`}

	${mobile`
		flex-direction: column;
		padding: 2rem 30px;
		margin-top: 2rem;
	`}

	> p {
		font-size: 1.2rem;
	}

	.react-select__control {
		background: #333 !important;
		color: #FFF !important;
		margin-top: 0.5rem;

		&:not(.react-select__control--menu-is-open) {
			border-color: #666 !important;
		}
	}
`

const Top = styled.div`
	display: flex;

	${mobile`
		flex-direction: column;
	`}
`

const Left = styled.div`

`

const CardclusterPerk = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;

	background: rgba(255, 255, 255, 0.05);
	border-radius: 24px;
	padding: 1.5rem 2rem;

	p {
		color: rgba(255, 255, 255, 0.7);
		font-size: 0.9rem;
		margin-bottom: 0;
	}
`

const CardclusterPerkHeading = styled.div`
	font-weight: 500;
	margin-bottom: 0.5rem;

	align-items: center;
	display: flex;

	svg {
		color: rgba(255, 255, 255, 0.7);
		margin-right: 1rem;
	}
`

const Disclaimer = styled.small`
	display: block;
	line-height: 1;
	margin-top: 2rem;

	p {
		font-size: 0.8rem;
		margin-bottom: 0.5rem;
	}
`

const Heading = styled.div`
	color: #FFF;
	font-size: 1.1rem;
	font-weight: bold;
	margin-bottom: 1.5rem;
`

const Text = styled.div`
	color: #EEE;
	max-width: 700px;

	> :last-child {
		margin-bottom: 0;
	}

	p > a {
		color: #FFF;
		font-weight: bold;
		text-decoration-color: ${props => props.theme.primary};
	}
`

const Logo = styled.span`
	img {
		height: 30px;
	}
`

const MenuColumns = styled.div`
	display: flex;
	gap: 4rem;

	${mobile`
		flex-direction: column;
		gap: 2rem;
	`}
`

const Menu = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 300px;
	flex: 1;

	> a, > div > a {
		color: #AAA;
		display: block;
		cursor: pointer;
		font-size: 1rem;
		margin-bottom: 1rem;
		text-decoration: none;
		width: fit-content;
		white-space: nowrap;

		${desktop`
			&:hover {
				color: #FFF;
			}
		`}

		&:last-child {
			margin-bottom: 0;
		}
	}
`

const Right = styled.div`
	${mobile`
		margin-top: 3rem;
	`}

	${desktop`
		margin-left: 8rem;
	`}
`

const Social = styled.div`
	margin-top: 2rem;

	p {
		font-size: 1rem;
	}
`

const List = styled.div`
	align-items: center;
	display: flex;
	gap: 1rem;
	margin-bottom: 2rem;

	a {
		color: #AAA;

		${desktop`
			&:hover {
				color: #FFF;
			}
		`}
	}

	&:last-child {
		margin-right: 0;
	}
`

const Copyright = styled.div`
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.9rem;
	margin-top: 3.5rem;
	text-align: center;
`

const MenuLinkPlaceholder = styled.div`
	height: calc(18px + 1rem);
`

const enhance = compose(
	connect(state => ({
		language: state.persist.language,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		setReference
	}, dispatch)),
	translate('general'),
	withTheme
)

export default enhance(Footer)
