const cardLanguages = {
	world: {
		name: 'general.cardLanguageWorldwide',
	},
	en: {
		name: 'general.languageEn',
		flag: 'GB',
		abbr: 'EN',
		color: 'en',
		adminOrder: 4
	},
	'en-US': {
		name: 'general.cardLanguageEnglishNA',
		flag: 'US',
		abbr: 'EN',
		color: 'en',
		adminOrder: 1,
		specificationOf: 'en'
	},
	'en-EU': {
		name: 'general.cardLanguageEnglishEU',
		flag: 'EU',
		abbr: 'EN',
		color: 'en',
		adminOrder: 2,
		specificationOf: 'en'
	},
	'en-AU': {
		name: 'general.cardLanguageEnglishAU',
		flag: 'AU',
		abbr: 'A',
		color: 'en',
		adminOrder: 3,
		other: true
	},
	de: {
		name: 'general.languageDe',
		flag: 'DE',
		abbr: 'DE',
		color: 'de',
		adminOrder: 7
	},
	fr: {
		name: 'general.languageFr',
		flag: 'FR',
		abbr: 'FR',
		color: 'fr',
		adminOrder: 5
	},
	'fr-CA': {
		name: 'general.cardLanguageFrenchCA',
		flag: 'CA',
		abbr: 'CA',
		color: 'fr',
		adminOrder: 6,
		other: true
	},
	it: {
		name: 'general.languageIt',
		flag: 'IT',
		abbr: 'IT',
		color: 'it',
		adminOrder: 8
	},
	es: {
		name: 'general.languageEs',
		flag: 'ES',
		abbr: 'SP',
		color: 'es',
		adminOrder: 12
	},
	pt: {
		name: 'general.languagePt',
		flag: 'PT',
		abbr: 'PT',
		color: 'pt',
		adminOrder: 9
	},
	'pt-BR': {
		name: 'general.cardLanguagePortugueseBR',
		flag: 'BR',
		abbr: 'PT',
		color: 'pt',
		adminOrder: 10,
		specificationOf: 'pt'
	},
	'pt-PT': {
		name: 'general.cardLanguagePortuguesePT',
		flag: 'PT',
		abbr: 'PT',
		color: 'pt',
		adminOrder: 11,
		specificationOf: 'pt'
	},
	jp: {
		name: 'general.languageJp',
		flag: 'JP',
		abbr: 'JP',
		color: 'jp',
		adminOrder: 13,
		other: true
	},
	kr: {
		name: 'general.languageKr',
		flag: 'KR',
		abbr: 'KR',
		color: 'kr',
		adminOrder: 14,
		other: true
	}
}

export default cardLanguages
