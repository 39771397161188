import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const SimpleProgressBar = ({ background, color, percent }) => (
	<StyledSimpleProgressBar
		background={background}
		color={color}
		percent={percent}
	>
		<span />
	</StyledSimpleProgressBar>
)

SimpleProgressBar.propTypes = {
	background: PropTypes.string,
	color: PropTypes.string,
	percent: PropTypes.number.isRequired
}

SimpleProgressBar.defaultProps = {
	background: null,
	color: null
}

export const StyledSimpleProgressBar = styled.div`
	background: linear-gradient(to right, transparent, ${props => props.theme[props.background]});
	border-radius: 2px;

	span {
		background: ${props => props.color ? props.theme[props.color] : props.theme.secondary};
		border-radius: 2px;
		display: block;
		height: 12px;
		width: ${props => props.percent}%;

		${props => props.percent === 100 && `
			background: ${props.theme.greenValid};
		`}
	}
`

export default SimpleProgressBar
