const editions = {
	'1E': {
		name: 'general.firstEdition',
		order: 1
	},
	UE: {
		name: 'general.unlimitedEdition',
		order: 2
	},
	LE: {
		name: 'general.limitedEdition',
		order: 3
	}
}

export default editions
