import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators, compose } from 'redux'
import Icon from '../../components/general/Icon'
import Link from '../../components/router/Link'
import Logo from '../../images/logo/cardcluster-white.svg'
import LogoShort from '../../images/logo/logo.svg'
import SiteSearch from '../../pages/search/SiteSearch'
import { isIos, isStandalone, mobileTablet } from '../../styles/media'
import MenuLink from '../../components/nav/MenuLink'
import translate from '../../helper/translate'
import { getScrollPosition, scrollTop, scrollToPosition } from '../../helper/scroll'
import DropDown, { StyledDropDown } from '../../components/button/DropDown'
import SaveButton from '../../pages/deck/components/SaveButton'
import { saveDeck } from '../../state/actionCreators/deckbuilder'
import history from '../../helper/history'
import AccountNotifications from './AccountNotifications'
import { closeDeck } from '../../helper/deck'
import { setConfirm, shouldReload } from '../../state/actions/app'
import { setDeck, discardChanges, clearDeck } from '../../state/actions/deckbuilder'
import ToolbarButton from '../../components/button/ToolbarButton'
import { updateStats } from '../../state/actionCreators/collection'
import CommentsDropDown from './CommentsDropDown'
import DeckLinks from '../MoreMenu/DeckLinks'
import { locationMatchesCardSearch } from '../../helper/router'

class MobileSiteHeader extends Component {
	static propTypes = {
		authToken: PropTypes.string,
		clearDeck: PropTypes.func.isRequired,
		deck: PropTypes.object,
		discardChanges: PropTypes.func.isRequired,
		updateStats: PropTypes.func.isRequired,
		history: PropTypes.object.isRequired,
		isLoading: PropTypes.bool.isRequired,
		location: PropTypes.object.isRequired,
		saveDeck: PropTypes.func.isRequired,
		sessionReturnLink: PropTypes.string,
		setConfirm: PropTypes.func.isRequired,
		setDeck: PropTypes.func.isRequired,
		shelvedDeck: PropTypes.object,
		shouldReload: PropTypes.func.isRequired,
		t: PropTypes.func.isRequired,
		title: PropTypes.string,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		authToken: null,
		deck: null,
		sessionReturnLink: null,
		shelvedDeck: null,
		title: null
	}

	handleLogoOnClick = () => {
		const { location } = this.props

		const isDeck = location.pathname.startsWith('/deck/')
		const isCardSearch = locationMatchesCardSearch(location)
		const isAddCards = location.pathname.startsWith('/add') || location.pathname.endsWith('/add') || location.pathname.includes('/add/')

		const scrolledTop = (isDeck ? document.querySelector('#tabDeckWrapper').scrollTop : getScrollPosition()) === 0

		if (scrolledTop && !isAddCards) {
			history.push('/white')

			window.setTimeout(() => {
				history.replace('/')
			}, 100)
		} else if (isCardSearch) {
			scrollToPosition(0, document.querySelector('#cardSearchResults'))
		} else if (isDeck) {
			scrollToPosition(0, document.querySelector('#tabDeckWrapper'))
		} else {
			scrollTop()
		}
	}

	render() {
		const { authToken, clearDeck, deck, discardChanges, updateStats, history, isLoading, location, presetLink, saveDeck, setConfirm, sessionReturnLink, setDeck, shelvedDeck, shouldReload, t, title, viewport } = this.props

		const backButtonVisible = isIos() && isStandalone() && history.length !== 0
		const isHome = false/*location.pathname === '/' && !isLoading*/
		const isDeckEditor = location.pathname.startsWith('/deck-builder/')
		const isAddCards = location.pathname.startsWith('/add') || location.pathname.endsWith('/add') || location.pathname.includes('/add/')
		const isCollectionManager = location.pathname.startsWith('/mycollections') || location.pathname.startsWith('/collection/')
		const isCardSearch = locationMatchesCardSearch(location)
		const viewsDeck = location.pathname.startsWith('/deck/') && deck
		const saveDisabled = !deck || (isDeckEditor && deck && (deck.saved || deck.saving))

		return (
			<>
				<StyledMobileSiteHeader
					authToken={authToken}
					isHome={isHome}
					disabled={isDeckEditor && isLoading}
					hidden={isCardSearch}
					ref={this.headerRef}
				>
					{!isAddCards && (
						<BackButton onClick={() => history.goBack()} visible={backButtonVisible}>
							<Icon name="chevron-left" size={30} />
						</BackButton>
					)}

					{!isDeckEditor && !isAddCards && (
						<MenuLink as="label" htmlFor="toggle-main-menu">
							<Icon name="menu" />
						</MenuLink>
					)}

					{isDeckEditor && authToken && (
						<>
							<SaveButton
								disabled={saveDisabled}
								onClick={!saveDisabled ? () => saveDeck(t) : undefined}
								saving={deck && deck.saving}
							>
								{deck && deck.saving ? t('general.saving') : t('general.save')}
							</SaveButton>
						</>
					)}

					{!backButtonVisible && !isDeckEditor && !isAddCards && <Spacer />}

					{!isDeckEditor && !isAddCards && (
						<LogoLinkWrapper>
							<LogoLink onClick={this.handleLogoOnClick}>
								<img src={Logo} alt="cardcluster" />
							</LogoLink>
						</LogoLinkWrapper>
					)}

					{!isHome && (isDeckEditor || isAddCards) && (
						<LogoLinkWrapper isAddCards={isAddCards}>
							<LogoLink onClick={this.handleLogoOnClick}>
								<img src={LogoShort} alt="cardcluster" />
							</LogoLink>
						</LogoLinkWrapper>
					)}

					{isAddCards && (
						<AddCardsHeading onClick={this.handleLogoOnClick}>
							{t('general.addCards')}
						</AddCardsHeading>
					)}

					{backButtonVisible && isDeckEditor && <Spacer />}

					{authToken && isDeckEditor && (
						<>
							<MenuLink
								yellow
								onClick={() => history.push('/mydecks')}
							>
								<Icon name="minus" />
							</MenuLink>
							<MenuLink
								red
								onClick={(event) => {
									event.preventDefault()
									closeDeck(deck, shelvedDeck, setConfirm, saveDeck, discardChanges, setDeck, clearDeck, location, viewport, t)
								}}
							>
								<Icon name="x" />
							</MenuLink>
						</>
					)}

					{!isDeckEditor && !isAddCards && (
						<>
							<SiteSearch />

							{!viewsDeck && (
								<CommentsDropDown
									button={(
										<MenuLink>
											<Icon name="chat-alt-2" />
											<AccountNotifications type="comments" />
										</MenuLink>
									)}
								/>
							)}
						</>
					)}

					{viewsDeck && !isAddCards && (
						<DropDown button={<MenuLink><Icon name="dots-horizontal" /></MenuLink>}>
							{onBeforeNavigate => deck ? (
								<DeckLinks onBeforeNavigate={onBeforeNavigate} />
							) : null}
						</DropDown>
					)}
				</StyledMobileSiteHeader>
			</>
		)
	}
}

const StyledMobileSiteHeader = styled.div`
	background: ${props => props.theme.headerMobile};

	align-items: center;
	display: flex;
	height: 44px;

	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: ${props => props.theme.zLayer6};

	${MenuLink} {
		border-radius: 4px;
		color: ${props => props.theme.white};
		height: 100%;
		margin-bottom: 0;
		width: 44px;

		align-items: center;
		display: flex;

		&:active {
			background: #444;
		}
	}

	${StyledDropDown} {
		height: 100%;
	}

	${ToolbarButton} {
		margin-right: 0.5rem;
	}

	${props => props.disabled && `
		pointer-events: none;

		${MenuLink} {
			opacity: 0.5;
		}
	`}

	${props => props.hidden && `
		display: none;
	`}

	${props => props.isHome && !props.authToken && `
		background: #2a4859;
	`}

	${mobileTablet`
		display: flex;
	`}
`

const ScrollTopTrigger = styled(Link)`
	position: absolute;
	left: 86px;
	right: 86px;
	top: 0;
	bottom: 0;
`

const BackButton = styled(MenuLink)`
	position: ${props => props.visible ? 'static' : 'absolute'};
	opacity: ${props => props.visible ? 1 : 0};
	transition: all 0.2s;
	transform: ${props => props.visible ? 'translate(0, 0)' : 'translate(-100%, 0)'};

	svg {
		position: absolute;
		top: 7px;
	}
`

const LogoLinkWrapper = styled.div`
	height: 100%;

	align-items: center;
	display: flex;
	justify-content: center;

	${props => !props.isAddCards && `
		flex: 1;
	`}

	${props => props.isAddCards && `
		margin-left: 1rem;

		img {
			width: 27px;
			min-width: 27px;
		}
	`}
`

const LogoLink = styled(Link)`
	align-items: center;
	display: flex;
	justify-content: center;

	height: 100%;
	width: fit-content;

	img {
		height: 27px;
	}
`

const Title = styled.div`
	color: ${props => props.theme.white};
	font-size: 1.1rem;
	font-weight: 500;
	margin: 0 0.5rem;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
	white-space: nowrap;

	flex: 1;
`

const Spacer = styled.div`
	width: 44px;
`

const EndSessionButtonWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
`

const AddCardsHeading = styled.div`
	color: ${props => props.theme.white};
	font-size: 1.1rem;
	margin-left: 0.75rem;
	margin-right: auto;
	font-weight: 600;
`

const enhance = compose(
	connect(state => ({
		authToken: state.persist.authToken,
		deck: state.deckbuilder.deck,
		isLoading: state.app.isLoading,
		sessionReturnLink: state.collection.sessionReturnLink,
		shelvedDeck: state.deckbuilder.shelvedDeck,
		title: state.app.title,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		clearDeck,
		discardChanges,
		updateStats: updateStats,
		saveDeck,
		setConfirm,
		setDeck,
		shouldReload
	}, dispatch)),
	withRouter,
	translate('general')
)

export default enhance(MobileSiteHeader)
