import React, { Component, Fragment } from 'react'
import replace from 'react-string-replace'
import { cardNameLanguageMapping, domains, languageDefaults, translationsMapping } from '../../config'
import store from '../state/store'
import { setTranslations } from '../state/actions/app'
import { connect } from 'react-redux'

/**
 * Use the @translate annotation to import translation strings into a component.
 * This high order component injects the function t as props that you can use to access translations.
 *
 * @param dependencies	string | array
 *
 * @return Wrapped component
 */
const enhance = connect(state => ({
	translations: state.app.translations,
	translationHelper: state.persist.user.settings['app.translationHelper'],
	userLanguage: state.persist.language
}))

const translate = dependencies => ComposedComponent => enhance(class extends Component {
	constructor(props) {
		super(props)

		this.state = {
			translationLanguage: translationsMapping[props.userLanguage] || 'en',
			showKey: false
		}
	}

	componentDidMount() {
		this.load()
	}

	componentDidUpdate(prevProps) {
		const { translations, userLanguage } = this.props
		const { translationLanguage } = this.state

		if (
			translationsMapping[userLanguage] !== translationLanguage
			&& (
				prevProps.translations[translationsMapping[userLanguage]] !== translations[translationsMapping[userLanguage]]
				|| (
					prevProps.translations[translationsMapping[userLanguage]]
					&& translations[translationsMapping[userLanguage]]
					&& Object.keys(prevProps.translations[translationsMapping[userLanguage]]).length !== Object.keys(translations[translationsMapping[userLanguage]]).length
				)
				|| (
					translationsMapping[prevProps.userLanguage] !== translationsMapping[userLanguage]
					&& translations[translationsMapping[userLanguage]]
				)
			)
		) {
			this.setState({
				translationLanguage: translationsMapping[userLanguage]
			})
		}
	}

	load = () => {
		const { userLanguage } = this.props

		const language = translationsMapping[userLanguage] || 'en'

		if (Array.isArray(dependencies)) {
			for (const namespace of dependencies) {
				this.getTranslations(namespace, language)
			}
		} else {
			this.getTranslations(dependencies, language)
		}
	}

	getTranslations = (namespace, language) => {
		if (
			!(
				store.getState().app.translations &&
				store.getState().app.translations[language] &&
				store.getState().app.translations[language][namespace]
			)
		) {
			import(`../translations/${namespace}/${namespace === 'admin' && language !== 'de' && language !== 'en' ? 'en' : language}.json`).then(module => {
				store.dispatch(setTranslations(language, namespace, module.default))
			})
		}
	}

	render() {
		const { translationHelper, translations } = this.props
		const { translationLanguage, showKey } = this.state

		const languageData = translations ? translations[translationLanguage] : null

		return languageData ? (
			<ComposedComponent
				{...this.props}
				t={(param, data, plainStringReplace = false) => t(param, data, languageData, plainStringReplace, translationHelper)}
			/>
		) : null
	}
})

const t = (param, data, translations, plainStringReplace, showKey = false) => {
	if (showKey) {
		return param
	}

	const arr = param.split('.')
	const namespace = arr[0]

	if (!translations || !translations[namespace]) {
		return ''
	}

	let output = arr.length === 2 ? translations[namespace][arr[1]] : translations[namespace]

	// plurals
	if (data && typeof data.count !== 'undefined') {
		const { count } = data

		if (count !== 1) {
			output = arr.length === 2 ? translations[namespace][`${arr[1]}Plural`] : translations[`${namespace}Plural`]
			data.count = data.count ? data.count.toLocaleString() : 0
		}
	}

	// replacements
	if (data && output) {
		if (plainStringReplace) {
			Object.entries(data).forEach(([key, value]) => {
				output = output.replace(new RegExp(`\{\{${key}\}\}`, 'g'), value)
			})
		} else {
			output = replace(output, /\{\{(.*?)\}\}/g, (match, index) => <Fragment key={index}>{data[match]}</Fragment>)
		}
	}

	return typeof output !== 'undefined' ? output : param
}

/**
 * Languages are stored as a combination of language code and country code (e.g. en-US).
 * The host language is used whether to suggest the user to use a different domain.
 */
export const detectLanguage = (skipSaved = false) => {
	// use saved language if set
	const stored = store.getState().persist.language

	if (!skipSaved && stored !== null && Object.keys(translationsMapping).find(language => language === stored)) {
		return [stored, false]
	}

	const hostLanguage = getHostLanguage()
	const browserLanguage = getBrowserLanguage()
	const mismatch = hostLanguage !== cardNameLanguageMapping[browserLanguage]

	const translateLanguage = !mismatch ? browserLanguage : languageDefaults[hostLanguage]

	return [translateLanguage, mismatch]
}

const getHostLanguage = () => {
	if (process.env.NODE_ENV === 'development') {
		return 'de'
	}

	const domainMatch = Object.entries(domains).find(([key, value]) => window.location.hostname === value)

	if (domainMatch) {
		return domainMatch[0]
	}

	return 'en'
}

export const getBrowserLanguage = () => {
	for (const language of window.navigator.languages) {
		if (language in translationsMapping) {
			return language
		}

		if (language.includes('de')) {
			return 'de-DE'
		}

		if (language.includes('fr')) {
			return 'en-FR'
		}

		if (language.includes('it')) {
			return 'en-IT'
		}

		if (language.includes('es')) {
			return 'en-ES'
		}

		if (language.includes('pt')) {
			return 'en-PT'
		}
	}

	return 'en-US'
}

export default translate
