export const USD = 'USD'
export const EUR = 'EUR'

const currencies = {
	[USD]: {
		name: 'USD',
		prefix: '$ ',
		suffix: null,
		decimalSeparator: '.',
		groupSeparator: ',',
		locale: 'en-US'
	},
	[EUR]: {
		name: 'EUR',
		prefix: null,
		suffix: ' €',
		decimalSeparator: ',',
		groupSeparator: '.',
		locale: 'de-DE'
	}
}

export default currencies
