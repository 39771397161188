import isBefore from 'date-fns/isBefore'
import { post } from './api'
import { getPrintNumber } from './print'
import { cardLanguageOptionsPlainList, editionOptions } from '../data'
import cardLanguages from '../data/cardLanguages'
import { domains, translationsMapping } from '../../config'
import { trackDownload } from './track'
import { languagesGeneric } from '../data/languages'
import { getCardName } from './card'
import { copyToClipboard } from './string'

export const getCollectionsOptions = (collections, t, allowOnlyType = null, showAdd = true, excludeSlug = null) => [
	{
		label: 'Haves',
		options: [
			...collections.filter(item => item.slug !== excludeSlug && (item.type === 'haves' || item.type === 'collection')).map(item => ({
				...item,
				isDisabled: allowOnlyType && item.type !== allowOnlyType && item.type !== 'collection'
			})),
			...(showAdd ? [{
				value: 'newHaves',
				label: t('general.newCollection'),
				icon: 'add-circle',
				create: true
			}] : [])
		]
	},
	{
		label: 'Wants',
		options: [
			...collections.filter(item => item.slug !== excludeSlug && item.type === 'wants').map(item => ({
				...item,
				isDisabled: allowOnlyType && item.type !== allowOnlyType && item.type !== 'collection'
			})),
			...(showAdd ? [{
				value: 'newWants',
				label: t('general.newCollection'),
				icon: 'add-circle',
				create: true
			}] : [])
		]
	}
]

export const getSuggestedCollection = (collections, type = null, excludeSlug = null) => {
	if (!collections) return null

	const collection = collections.reduce((a, b) => {
		if (b.slug === excludeSlug || (type && type !== b.type && b.type !== 'collection')) {
			return a
		}

		if (!a) {
			return b
		}

		if (isBefore(new Date(a.updatedAt), new Date(b.updatedAt))) {
			return b
		}

		return a
	}, null)

	return collection?.slug
}

export const loadHaves = (collection, cardIds, artworkIds, printIds, deckSlug, onSuccess, onError, loadedHaves) => {
	if (!cardIds) return null

	const filteredCardIds = loadedHaves ? cardIds.filter(item => !(`card-${item}` in loadedHaves)) : cardIds || []
	const filteredArtworkIds = loadedHaves ? artworkIds.filter(item => !(`artwork-${item}` in loadedHaves)) : artworkIds || []

	if (filteredCardIds.length > 0 || filteredArtworkIds.length > 0 || (printIds && printIds.length > 0)) {
		return post(`haves?collection=${collection}${deckSlug ? `&deckSlug=${deckSlug}` : ''}`, {
			...(filteredCardIds && { cardIds: [...new Set(filteredCardIds)] }),
			...(filteredArtworkIds && { artworkIds: [...new Set(filteredArtworkIds)] }),
			...(printIds && { printIds: [...new Set(printIds)] })
		}, onSuccess, onError)
	}

	return null
}

export const getCollectionCardImage = (print, collectionCardLanguage) => {
	const imageMatchingLanguage = print.images ? print.images.find(item => item.language === collectionCardLanguage) : null

	if (imageMatchingLanguage) {
		return imageMatchingLanguage.image
	}

	return print.image || print.card.image
}

export const getCollectionCardName = (collectionCard, userLanguage) => getPrintNumber(collectionCard.print, collectionCard.language, userLanguage, true)

export const getAvailableLanguageOptions = (print, cardNameLanguage, t) => {
	let filteredLanguageOptions = print.language ? [
		cardLanguageOptionsPlainList(t).find(item => item.value === print.language.language)
	] : cardLanguageOptionsPlainList(t).filter((item) => {
		if (print.set?.languages?.length > 0 && !print.set?.languages?.find(item2 => item2.language === item.value)) {
			return false
		}

		return true
	})

	filteredLanguageOptions = filteredLanguageOptions.sort((a, b) => {
		if (a.value === cardNameLanguage && b.value !== cardNameLanguage) return -1
		if (a.value !== cardNameLanguage && b.value === cardNameLanguage) return 1

		return 0
	})

	const hasEnglishWorldwide = !!(filteredLanguageOptions.find(item => item.value === 'en') && !filteredLanguageOptions.find(item => item.value === 'en-US') && !filteredLanguageOptions.find(item => item.value === 'en-EU'))
	const hasPortugueseWorldwide = !!(filteredLanguageOptions.find(item => item.value === 'pt') && !filteredLanguageOptions.find(item => item.value === 'pt-BR') && !filteredLanguageOptions.find(item => item.value === 'pt-PT'))

	if (hasEnglishWorldwide || hasPortugueseWorldwide) {
		filteredLanguageOptions = [
			...filteredLanguageOptions,
			{
				label: t('general.languageVariants'),
				options: [
					...(hasEnglishWorldwide ? [
						{
							value: 'en-US',
							label: t(cardLanguages['en-US'].name)
						},
						{
							value: 'en-EU',
							label: t(cardLanguages['en-EU'].name)
						}
					] : []),
					...(hasPortugueseWorldwide ? [
						{
							value: 'pt-BR',
							label: t(cardLanguages['pt-BR'].name)
						},
						{
							value: 'pt-PT',
							label: t(cardLanguages['pt-PT'].name)
						}
					] : [])
				]
			}
		]
	}

	return filteredLanguageOptions
}

export const getPreselectedLanguageValue = (filteredLanguageOptions, language) => {
	return language && filteredLanguageOptions && filteredLanguageOptions.length > 0 && !filteredLanguageOptions.find((item) => {
		return (item.options && item.options.find(item2 => item2.value === language)) || item.value === language
	}) ? filteredLanguageOptions[0].value : language
}

const printHasEdition = (editionLabel, print, language, specificationOf) => {
	return (print.language && print.language[editionLabel])
		|| (print.set?.languages?.length > 0
			&& language
			&& print.set.languages.find(item2 => (
				item2.language === language
				|| (specificationOf && item2.language === specificationOf)
			) && item2[editionLabel])
		)
}

export const getAvailableEditionOptions = (print, t, language) => {
	const specificationOf = language ? cardLanguages[language].specificationOf : null

	return editionOptions(t).filter((item) => {
		if (item.value === '1E' && printHasEdition('firstEdition', print, language, specificationOf)) {
			return true
		}

		if (item.value === 'UE' && printHasEdition('unlimitedEdition', print, language, specificationOf)) {
			return true
		}

		if (item.value === 'LE' && printHasEdition('limitedEdition', print, language, specificationOf)) {
			return true
		}

		return false
	})
}

export const getPreselectedEditionValue = (filteredEditionOptions, edition) => {
	return edition && filteredEditionOptions && filteredEditionOptions.length > 0 && !filteredEditionOptions.find(item => item.value === edition) ? filteredEditionOptions[0].value : edition
}

export const downloadCollectionFile = (data, what, userLanguage) => {
	const domain = domains[translationsMapping[userLanguage]]

	trackDownload(`https://${domain}/api/collectionImports/${data.id}/download?what=${what}`)

	if (process.env.NODE_ENV === 'development') {
		window.location.href = `http://localhost:${process.env.DEV_PORT || 3000}/api/collectionImports/${data.id}/download?what=${what}&token=${data.token}`
	} else {
		window.location.href = `https://${domain}/api/collectionImports/${data.id}/download?what=${what}&token=${data.token}`
	}
}

export const normalizeColumns = (columns, collectionType) => {
	let arr = columns

	const hasHavesColumn = arr.indexOf('haves') !== -1
	const hasWantsColumn = arr.indexOf('wants') !== -1
	const hasNameColumn = arr.indexOf('name') !== -1

	if (!hasNameColumn) {
		arr = [
			'name',
			...arr
		]
	}

	if ((collectionType === 'wants' || collectionType === 'collection') && !hasWantsColumn) {
		arr = [
			'wants',
			...arr
		]
	}

	if ((collectionType === 'haves' || collectionType === 'collection') && !hasHavesColumn) {
		arr = [
			'haves',
			...arr
		]
	}

	return arr
}

export const getMatchingPreset = (presets, settings) => (presets || []).find(item => item.quantity === (settings['collection.defaultQuantity'] ? Number(settings['collection.defaultQuantity']) : null)
	&& item.language === settings['collection.defaultLanguage']
	&& item.edition === settings['collection.defaultEdition']
	&& item.condition === settings['collection.defaultCondition']
	&& (!item.notes || item.notes === settings['collection.defaultNotes'])
	&& item.storage === (settings['collection.defaultStorage'] ? Number(settings['collection.defaultStorage']) : null)
	&& (!item.deck || item.deck === settings['collection.defaultDeck'])
	&& item.origin === settings['collection.defaultOrigin']
	&& (!item.date || item.date === settings['collection.defaultDate'])
	&& item.altered === settings['collection.defaultAltered']
	&& item.signed === settings['collection.defaultSigned']
	&& item.gradingService === settings['collection.defaultGradingService']
	&& item.grade === settings['collection.defaultGrade'])

export const applyPreset = (updateUserSettings, preset) => {
	if (!preset) {
		preset = {
			quantity: null,
			language: null,
			edition: null,
			condition: null,
			notes: null,
			storage: null,
			deck: null,
			origin: null,
			date: null,
			altered: null,
			signed: null,
			gradingService: null,
			grade: null
		}
	}

	updateUserSettings([
		{
			name: 'collection.defaultQuantity',
			value: preset.quantity
		},
		{
			name: 'collection.defaultLanguage',
			value: preset.language
		},
		{
			name: 'collection.defaultEdition',
			value: preset.edition
		},
		{
			name: 'collection.defaultCondition',
			value: preset.condition
		},
		{
			name: 'collection.defaultNotes',
			value: preset.notes
		},
		{
			name: 'collection.defaultStorage',
			value: preset.storage
		},
		{
			name: 'collection.defaultDeck',
			value: preset.deck
		},
		{
			name: 'collection.defaultOrigin',
			value: preset.origin
		},
		{
			name: 'collection.defaultDate',
			value: preset.date
		},
		{
			name: 'collection.defaultAltered',
			value: preset.altered
		},
		{
			name: 'collection.defaultSigned',
			value: preset.signed
		},
		{
			name: 'collection.defaultGradingService',
			value: preset.gradingService
		},
		{
			name: 'collection.defaultGrade',
			value: preset.grade
		},
	], true)
}

export const hasAnyDataFieldDisabled = settings => settings['collection.disableLanguage'] || settings['collection.disableEdition'] || settings['collection.disableCondition'] || settings['collection.disableNotes'] || settings['collection.disableLocation'] || settings['collection.disableOrigin'] || settings['collection.disableAltered'] || settings['collection.disableSigned'] || settings['collection.disableGradedCards']

export const getKeyBindLanguages = cardNameLanguage => languagesGeneric.sort((a, b) => {
	if (a === cardNameLanguage && b !== cardNameLanguage) return -1
	if (a !== cardNameLanguage && b === cardNameLanguage) return 1

	return 0
})

export const copyCollectionCardsToClipboard = (items, cardNameLanguage, showToast, t, checked = []) => {
	const str = items.filter(item => checked.length === 0 || checked.indexOf(item.id) !== -1).reduce((a, b) => {
		a += `${b.wants || b.haves}x ${getCardName(b.print, cardNameLanguage)} (${b.print.set.name})\n`

		return a
	}, '')

	copyToClipboard(str)

	showToast('success', t('general.copied'))
}
