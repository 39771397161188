export const START_SESSION = 'START_SESSION'
export const startSession = sessionReturnLink => ({
	type: START_SESSION,
	sessionReturnLink
})

export const SET_SESSION_RETURN_LINK = 'SET_SESSION_RETURN_LINK'
export const setSessionReturnLink = sessionReturnLink => ({
	type: SET_SESSION_RETURN_LINK,
	sessionReturnLink
})

export const INCREASE_DIRTY_ROWS = 'INCREASE_DIRTY_ROWS'
export const increaseDirtyRows = () => ({
	type: INCREASE_DIRTY_ROWS
})

export const SWITCH_WHAT_TO_CAPTURE = 'SWITCH_WHAT_TO_CAPTURE'
export const switchWhatToCapture = () => ({
	type: SWITCH_WHAT_TO_CAPTURE
})

export const RESET_SESSION = 'RESET_SESSION'
export const resetSession = () => ({
	type: RESET_SESSION
})

export const SET_END_SESSION_LOADING = 'SET_END_SESSION_LOADING'
export const setEndSessionLoading = () => ({
	type: SET_END_SESSION_LOADING
})

export const HANDLE_COLLECTION_CARD_ADDED = 'HANDLE_COLLECTION_CARD_ADDED'
export const handleCollectionCardAdded = (quantity, price, collectionSlug) => ({
	type: HANDLE_COLLECTION_CARD_ADDED,
	quantity,
	price,
	collectionSlug
})

export const QUEUE_COLLECTION_STATS_UPDATE = 'QUEUE_COLLECTION_STATS_UPDATE'
export const queueCollectionStatsUpdate = collectionSlug => ({
	type: QUEUE_COLLECTION_STATS_UPDATE,
	collectionSlug
})

export const START_AUTO_ADD = 'START_AUTO_ADD'
export const startAutoAdd = (autoAddGroup = null) => ({
	type: START_AUTO_ADD,
	autoAddGroup
})

export const INCREASE_AUTO_ADD_INDEX = 'INCREASE_AUTO_ADD_INDEX'
export const increaseAutoAddIndex = () => ({
	type: INCREASE_AUTO_ADD_INDEX
})

export const END_AUTO_ADD = 'END_AUTO_ADD'
export const endAutoAdd = (addAllBatchSuccess = false) => ({
	type: END_AUTO_ADD,
	addAllBatchSuccess
})

export const SET_ADD_ALL_BATCH_PRINT_IDS = 'SET_ADD_ALL_BATCH_PRINT_IDS'
export const setAddAllBatchPrintIds = addAllBatchPrintIds => ({
	type: SET_ADD_ALL_BATCH_PRINT_IDS,
	addAllBatchPrintIds
})
