const contactReasons = [
	{
		label: 'general.contactReasonQuestion',
		value: 'question'
	},
	{
		label: 'general.contactReasonIdea',
		value: 'idea'
	},
	{
		label: 'general.contactReasonDatabase',
		value: 'database'
	},
	{
		label: 'general.contactReasonBug',
		value: 'bug'
	},
	{
		label: 'general.contactReasonFeedback',
		value: 'feedback'
	},
	{
		label: 'general.contactReasonTranslate',
		value: 'translate'
	},
	{
		label: 'general.contactReasonBusinessInquiry',
		value: 'business'
	}
]

export default contactReasons
