import TCGplayerLogoRow1x from '../images/partner/tcgplayer-row-black@1x.png'
import TCGplayerLogoRow2x from '../images/partner/tcgplayer-row-black@2x.png'
import TCGplayerLogoRow3x from '../images/partner/tcgplayer-row-black@3x.png'
import TCGplayerLogoWhite1x from '../images/partner/tcgplayer-row-white@1x.png'
import TCGplayerLogoWhite2x from '../images/partner/tcgplayer-row-white@2x.png'
import TCGplayerLogoWhite3x from '../images/partner/tcgplayer-row-white@3x.png'
import TCGplayerLogoColumn1x from '../images/partner/tcgplayer-column@1x.png'
import TCGplayerLogoColumn2x from '../images/partner/tcgplayer-column@2x.png'
import TCGplayerLogoColumn3x from '../images/partner/tcgplayer-column@3x.png'
import CardmarketLogoRow1x from '../images/partner/cardmarket-row-blue@1x.png'
import CardmarketLogoRow2x from '../images/partner/cardmarket-row-blue@2x.png'
import CardmarketLogoRow3x from '../images/partner/cardmarket-row-blue@3x.png'
import CardmarketLogoWhite1x from '../images/partner/cardmarket-row-white@1x.png'
import CardmarketLogoWhite2x from '../images/partner/cardmarket-row-white@2x.png'
import CardmarketLogoWhite3x from '../images/partner/cardmarket-row-white@3x.png'
import CardmarketLogoColumn1x from '../images/partner/cardmarket-column-blue@1x.png'
import CardmarketLogoColumn2x from '../images/partner/cardmarket-column-blue@2x.png'
import CardmarketLogoColumn3x from '../images/partner/cardmarket-column-blue@3x.png'
import CardmarketLogoColumnWhite1x from '../images/partner/cardmarket-column-white@1x.png'
import CardmarketLogoColumnWhite2x from '../images/partner/cardmarket-column-white@2x.png'
import CardmarketLogoColumnWhite3x from '../images/partner/cardmarket-column-white@3x.png'
import { cardNameLanguageMapping } from '../../config'

export const TCG_PLAYER = 1
export const CARD_MARKET = 2

const priceSources = {
	[TCG_PLAYER]: {
		name: 'TCGplayer',
		currency: 'USD',
		logo1x: TCGplayerLogoRow1x,
		logo2x: TCGplayerLogoRow2x,
		logo3x: TCGplayerLogoRow3x,
		logoWhite1x: TCGplayerLogoWhite1x,
		logoWhite2x: TCGplayerLogoWhite2x,
		logoWhite3x: TCGplayerLogoWhite3x,
		logoColumn1x: TCGplayerLogoColumn1x,
		logoColumn2x: TCGplayerLogoColumn2x,
		logoColumn3x: TCGplayerLogoColumn3x,
		logoColumnWhite1x: TCGplayerLogoColumn1x,
		logoColumnWhite2x: TCGplayerLogoColumn2x,
		logoColumnWhite3x: TCGplayerLogoColumn3x,
		icon: 'currency-dollar',
		affiliate: true,
		color: 'tcgplayer',
		buildLink: (externalLink) => {
			if (!externalLink) return null

			return `https://partner.tcgplayer.com/o4yWqb?u=${encodeURIComponent(`https://www.tcgplayer.com${externalLink}`)}`
		}
	},
	[CARD_MARKET]: {
		name: 'Cardmarket',
		currency: 'EUR',
		icon: 'currency-euro',
		logo1x: CardmarketLogoRow1x,
		logo2x: CardmarketLogoRow2x,
		logo3x: CardmarketLogoRow3x,
		logoWhite1x: CardmarketLogoWhite1x,
		logoWhite2x: CardmarketLogoWhite2x,
		logoWhite3x: CardmarketLogoWhite3x,
		logoColumn1x: CardmarketLogoColumn1x,
		logoColumn2x: CardmarketLogoColumn2x,
		logoColumn3x: CardmarketLogoColumn3x,
		logoColumnWhite1x: CardmarketLogoColumnWhite1x,
		logoColumnWhite2x: CardmarketLogoColumnWhite2x,
		logoColumnWhite3x: CardmarketLogoColumnWhite3x,
		affiliate: true,
		color: 'cardmarket',
		buildLink: (externalLink, userLanguage, settings = null) => {
			if (!externalLink) return null

			const language = cardNameLanguageMapping[userLanguage]
			const cardmarketLanguage = ['en', 'de', 'fr', 'it', 'es'].find(item => item === language) ? language : 'en'

			let filterParams = ''

			if (settings && externalLink !== '/YuGiOh/Wants') {
				const filterSellerCountries = settings['site.cardmarketSellerCountries']
				const filterSellerType = settings['site.cardmarketSellerType']
				const filterSellerReputation = settings['site.cardmarketSellerReputation']
				const filterLanguage = settings['site.cardmarketLanguage']
				const filterMinCondition = settings['site.cardmarketMinCondition']
				const filterFirstEdition = settings['site.cardmarketFirstEdition']

				if (filterSellerCountries && Array.isArray(filterSellerCountries) && filterSellerCountries.length > 0) {
					filterParams += `&sellerCountry=${filterSellerCountries.toString()}`
				}

				if (filterSellerType && Array.isArray(filterSellerType) && filterSellerType.length > 0) {
					filterParams += `&sellerType=${filterSellerType.toString()}`
				}

				if (filterSellerReputation && Number(filterSellerReputation) !== 5) {
					filterParams += `&sellerReputation=${filterSellerReputation}`
				}

				if (filterLanguage && Array.isArray(filterLanguage) && filterLanguage.length > 0) {
					filterParams += `&language=${filterLanguage.toString()}`
				}

				if (filterMinCondition && Number(filterMinCondition) !== 7) {
					filterParams += `&minCondition=${filterMinCondition}`
				}

				if (filterFirstEdition && filterFirstEdition !== 'any') {
					filterParams += `&isFirstEd=${filterFirstEdition}`
				}
			}

			return `https://www.cardmarket.com/${cardmarketLanguage}${externalLink}?utm_source=cardcluster&utm_medium=text&utm_campaign=card_prices${filterParams}`
		}
	}
}

export default priceSources
