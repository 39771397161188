const themeLight = {
	id: 'light',

	fontDefault: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	fontMonospace: '"SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace',

	// background colors
	header: 'linear-gradient(to right bottom, #1A1A1A, #404040)',
	headerMobile: '#2D2D2D',
	background: '#FFF',
	backgroundLightest: '#FAFAFA',
	backgroundVeryLight: '#F5F5F5',
	backgroundLight: '#EEE',
	backgroundSolid: '#CCC',
	backgroundConcrete: '#DDD',
	backgroundDark: '#777',
	backgroundSemi: 'rgba(255, 255, 255, 0.9)',
	backgroundOverlay: 'rgba(0, 0, 0, 0.8)',
	backgroundHighlight: '#FDF7E2',
	backgroundDisabled: 'repeating-linear-gradient(-45deg,rgba(220,220,220,0.5),rgba(220,220,220,0.5) 5px,rgba(220,220,220,0.8)5px,rgba(220,220,220,0.8) 10px)',
	backgroundInverted: '#111',
	tooltipBackground: 'rgba(20, 20, 20)',
	priceSmall: 'rgba(255, 237, 204, 0.5)',
	priceHigh: 'rgba(255, 237, 204, 1)',
	pricingBackground: '#ddeaff',
	pricingBackgroundGradient: 'linear-gradient(#f9fbff, #ddeaff)',
	pricingBackgroundGradient2: 'linear-gradient(#ddeaff, #f9fbff)',

	// effects
	effectActivation: '#E9F4FF',
	effectCosts: '#FAF0E6',
	highlight: '#FFFF00',

	// primary colors
	primary: '#3A86FF',
	primaryHover: '#0767ff',
	primaryLight: '#C1D7EA',
	primaryVeryLight: '#DAE6F2',
	primaryVeryLightHover: '#EDF3F8',
	primaryGray: '#a1b2c0',
	secondary: '#555555',
	secondaryHover: '#2b2b2b',

	// text colors
	text: '#000',
	textLightened: '#333',
	textSecondary: '#555',
	textLight: '#777',
	textSlightlyLight: '#999',
	textVeryLight: '#AAA',
	textPrimaryVeryLight: '#828A92',
	textPrimaryVeryLightHover: '#47484A',
	textTranslucentLight: 'rgba(0, 0, 0, 0.4)',
	white: '#FFF',

	textInverted: '#FFF',
	textInvertedLight: '#DDD',

	// shadows
	shadowLight: 'rgba(0, 0, 0, 0.15)',
	shadowNormal: 'rgba(0, 0, 0, 0.35)',
	shadowThick: 'rgba(0, 0, 0, 0.5)',
	shadowSearch: '0 2px 4px rgba(0, 0, 0, 0.05)',
	shadowDropDown: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',

	// input
	inputBorderTop: '#D9D9D9',
	inputBorderSides: '#CCC',
	inputBorderBottom: '#B3B3B3',

	inputBorderHoverTop: '#C9C9C9',
	inputBorderHoverSides: '#BBB',
	inputBorderHoverBottom: '#A3A3A3',

	inputBorderFocus: '#3A86FF',

	inputBackground: '#FFF',
	selectOptionHover: '#EEE',

	// status colors
	red: '#cc1e22',
	redHover: '#a0171b',
	redLight: '#fceded',
	orange: '#ffa400',
	orangeLight: '#ffedcc',
	yellow: '#FFCC00',
	yellowLight: '#fff4cc',
	green: '#1e7b1e',
	greenHover: '#145214',
	greenLight: '#d6f5d6',
	greenValid: '#2ab92a',
	greenBright: '#06d66e',

	// accent colors
	bronze: '#CD7F32',
	gold: '#FFC64C',
	gold2: '#B89130',
	middleBlueGreen: '#e5989b',
	teal: '#52D1DC',
	blue: '#0353a4',
	purple: '#623CEA',
	purpleToTeal: 'linear-gradient(to right bottom, #B52DFF, 60%, #3CF1FF)',
	black: '#111111',
	silver: '#C0C0C0',
	platinum: '#EDF6F9',
	sage: '#A07178',
	sageLight: '#EEE5E9',

	cardmarket: '#022370',
	tcgplayer: '#447ab5',

	// language colors
	languageEn: '#EAE4E9',
	languageDe: '#E2ECE9',
	languageFr: '#BEE1E6',
	languageIt: '#DFE7FD',
	languageEs: '#FDE2E4',
	languagePt: '#FFF1E6',
	languageJp: '#FAD2E1',
	languageKr: '#F0D2FA',

	// Dropdown
	zLayer1: 5,
	zLayer2: 10,

	// Menu
	zLayer3: 15,
	zLayer4: 20,
	zLayer5: 25,

	// Overlay
	zLayer6: 30,
	zLayer7: 35,
	zLayer8: 40,
	zLayer9: 45,

	zLayer10: 50,
	zLayer11: 55,
	zLayer12: 60,
	zLayer13: 65,
	zLayer14: 70
}

export default themeLight
