import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { mobile } from '../../../styles/media'
import translate from '../../../helper/translate'
import EmptySquare from '../../../images/emptySquare.svg'
import Icon from '../../general/Icon'
import FilterToString from '../FilterToString'
import { getContrastColor } from '../../../helper/color'
import LazyLoadIfNotPrerender from '../../image/LazyLoadedImage'

const ArchetypeName = ({ color, defaultCriteriaFilter, description, hideImage, hideNameMobile, icon, image, name, release, small, t, tiny, type }) => (
	<Wrapper
		color={color}
		small={small}
		tiny={tiny}
		hideNameMobile={hideNameMobile}
	>
		{icon && <Icon name={icon} />}
		{!hideImage && (
			<ArchetypeImage small={small} tiny={tiny}>
				<LazyLoadIfNotPrerender height={30} offset={300}>
					<img
						src={image ? `${image}-crop-30.webp` : EmptySquare}
						srcSet={image ? `${image}-crop-30.webp 1x, ${image}-crop-60.webp 2x, ${image}-crop-90.webp 3x` : null}
						alt=""
					/>
				</LazyLoadIfNotPrerender>
			</ArchetypeImage>
		)}
		<TextWrapper description={description} hideNameMobile={hideNameMobile}>
			{name}
			{defaultCriteriaFilter && (
				<DefaultCriteriaFilter>
					&nbsp;(
					<FilterToString filter={defaultCriteriaFilter} />
					)
				</DefaultCriteriaFilter>
			)}
			{description && <Description>{t(`general.${type}`)}</Description>}
			{release && <Release>{release}</Release>}
		</TextWrapper>
	</Wrapper>
)

ArchetypeName.propTypes = {
	color: PropTypes.string,
	defaultCriteriaFilter: PropTypes.object,
	description: PropTypes.bool,
	hideImage: PropTypes.bool,
	hideNameMobile: PropTypes.bool,
	icon: PropTypes.string,
	image: PropTypes.string,
	name: PropTypes.string.isRequired,
	release: PropTypes.string,
	small: PropTypes.bool,
	t: PropTypes.func.isRequired,
	tiny: PropTypes.bool,
	type: PropTypes.string
}

ArchetypeName.defaultProps = {
	color: null,
	defaultCriteriaFilter: null,
	description: false,
	hideImage: false,
	hideNameMobile: false,
	icon: null,
	image: null,
	release: null,
	small: false,
	tiny: false,
	type: null
}

const ArchetypeImage = styled.div`
	img, svg {
		height: 30px;
		width: 30px;

		margin-right: 0.5rem;
		vertical-align: middle;
	}

	img {
		border-radius: 15px;
	}

	${props => props.small && `
		img, svg {
			height: 20px;
			width: 20px;

			margin-right: 0.35rem;
		}

		img {
			border-radius: 10px;
		}
	`}

	${props => props.tiny && `
		img, svg {
			height: 12px;
			width: 12px;

			margin-right: 0.25rem;
		}

		img {
			border-radius: 6px;
		}
	`}
`

const Wrapper = styled.div`
	align-items: center;
	display: flex;
	position: relative;
	width: 100%;

	${props => props.small && `
		background: ${props.color};
		border-radius: 10px;
		color: ${getContrastColor(props.color)};
		font-size: 0.8rem;
		font-weight: 500;
		padding: 0.25rem 0.5rem 0.25rem 0.35rem;
		width: fit-content;
	`}

	${props => props.tiny && `
		background: ${props.color};
		border-radius: 6px;
		color: ${getContrastColor(props.color)};
		font-size: 0.7rem;
		font-weight: 500;
		height: 18px;
		padding: 0 0.35rem 0 0.25rem;
		width: fit-content;
	`}

	${mobile`
		${props => props.hideNameMobile && `
			${ArchetypeImage} img, ${ArchetypeImage} svg {
				margin-right: 0;
			}

			${props => props.small && `
				padding: 0 0.35rem;
			`}

			${props => props.tiny && `
				padding: 0 0.25rem;
			`}
		`}
	`}
`

const TextWrapper = styled.div`
	${props => props.description && `
		display: flex;
		flex-direction: column;
	`}

	${mobile`
		${props => props.hideNameMobile && `
			display: none;
		`}
	`}
`

const DefaultCriteriaFilter = styled.div`
	color: ${props => props.theme.textLight};
	display: inline;

	${mobile`
		font-size: 1.2rem;
	`}
`

const Description = styled.span`
	color: ${props => props.theme.textVeryLight};
	font-size: 0.7rem;
	font-weight: bold;
	margin-top: 0.15rem;
	text-transform: uppercase;
`

const Release = styled.span`
	color: ${props => props.theme.textLight};
	font-size: 1rem;

	${mobile`
		display: block;
		margin-top: 1rem;
	`}
`

const mapStateToProps = state => ({
	viewport: state.app.viewport
})

const enhance = compose(
	connect(mapStateToProps, null),
	translate('general')
)

export default enhance(ArchetypeName)
