const countries = {
	AR: {
		name_en: 'Argentina',
		name_de: 'Argentinien',
		name_fr: 'Argentine',
		name_it: 'Argentina',
		name_es: 'Argentina',
		name_pt: 'Argentina'
	},
	AT: {
		name_en: 'Austria',
		name_de: 'Österreich',
		name_fr: 'Autriche',
		name_it: 'Austria',
		name_es: 'Austria',
		name_pt: 'Áustria',
		region: 'europe'
	},
	AU: {
		name_en: 'Australia',
		name_de: 'Australien',
		name_fr: 'Australie',
		name_it: 'Australia',
		name_es: 'Australia',
		name_pt: 'Austrália',
		region: 'oceania'
	},
	BE: {
		name_en: 'Belgium',
		name_de: 'Belgien',
		name_fr: 'Belgique',
		name_it: 'Belgio',
		name_es: 'Bélgica',
		name_pt: 'Bélgica',
		region: 'europe'
	},
	BG: {
		name_en: 'Bulgaria',
		name_de: 'Bulgarien',
		name_fr: 'Bulgarie',
		name_it: 'Bulgaria',
		name_es: 'Bulgaria',
		name_pt: 'Bulgária',
		region: 'europe'
	},
	BO: {
		name_en: 'Bolivia',
		name_de: 'Bolivien',
		name_fr: 'Bolivie',
		name_it: 'Bolivia',
		name_es: 'Bolivia',
		name_pt: 'Bolívia'
	},
	BR: {
		name_en: 'Brazil',
		name_de: 'Brasilien',
		name_fr: 'Brésil',
		name_it: 'Brasile',
		name_es: 'Brasil',
		name_pt: 'Brasil'
	},
	CA: {
		name_en: 'Canada',
		name_de: 'Kanada',
		name_fr: 'Canada',
		name_it: 'Canada',
		name_es: 'Canadá',
		name_pt: 'Canadá',
		states: {
			AB: 'Alberta',
			BC: 'British Columbia',
			MB: 'Manitoba',
			NB: 'New Brunswick',
			NL: 'Newfoundland and Labrador',
			NS: 'Nova Scotia',
			ON: 'Ontario',
			PE: 'Prince Edward Island',
			QC: 'Quebec',
			SK: 'Saskatchewan'
		}
	},
	CH: {
		name_en: 'Switzerland',
		name_de: 'Schweiz',
		name_fr: 'Suisse',
		name_it: 'Svizzera',
		name_es: 'Suiza',
		name_pt: 'Suíça',
		region: 'europe'
	},
	CL: {
		name_en: 'Chile',
		name_de: 'Chile',
		name_fr: 'Chili',
		name_it: 'Cile',
		name_es: 'Chile',
		name_pt: 'Chile'
	},
	CO: {
		name_en: 'Colombia',
		name_de: 'Kolumbien',
		name_fr: 'Colombie',
		name_it: 'Colombia',
		name_es: 'Colombia',
		name_pt: 'Colômbia'
	},
	CR: {
		name_en: 'Costa Rica',
		name_de: 'Costa Rica',
		name_fr: 'Costa Rica',
		name_it: 'Costa Rica',
		name_es: 'Costa Rica',
		name_pt: 'Costa Rica'
	},
	CU: {
		name_en: 'Cuba',
		name_de: 'Kuba',
		name_fr: 'Cuba',
		name_it: 'Cuba',
		name_es: 'Cuba',
		name_pt: 'Cuba'
	},
	CY: {
		name_en: 'Cyprus',
		name_de: 'Zypern',
		name_fr: 'Chypre',
		name_it: 'Cipro',
		name_es: 'Chipre',
		name_pt: 'Chipre',
		region: 'europe'
	},
	CZ: {
		name_en: 'Czechia',
		name_de: 'Tschechien',
		name_fr: 'Tchéquie',
		name_it: 'Cechia',
		name_es: 'Chequia',
		name_pt: 'Tchéquia'
	},
	DE: {
		name_en: 'Germany',
		name_de: 'Deutschland',
		name_fr: 'Allemagne',
		name_it: 'Germania',
		name_es: 'Alemania',
		name_pt: 'Alemanha',
		region: 'europe'
	},
	DK: {
		name_en: 'Denmark',
		name_de: 'Dänemark',
		name_fr: 'Danemark',
		name_it: 'Danimarca',
		name_es: 'Dinamarca',
		name_pt: 'Dinamarca',
		region: 'europe'
	},
	DO: {
		name_en: 'Dominican Republic',
		name_de: 'Dominikanische Republik',
		name_fr: 'République dominicaine',
		name_it: 'Repubblica Dominicana',
		name_es: 'República Dominicana',
		name_pt: 'República Dominicana'
	},
	EC: {
		name_en: 'Ecuador',
		name_de: 'Ecuador',
		name_fr: 'Équateur',
		name_it: 'Ecuador',
		name_es: 'Ecuador',
		name_pt: 'Equador'
	},
	EE: {
		name_en: 'Estonia',
		name_de: 'Estland',
		name_fr: 'Estonie',
		name_it: 'Estonia',
		name_es: 'Estonia',
		name_pt: 'Estônia',
		region: 'europe'
	},
	EG: {
		name_en: 'Egypt',
		name_de: 'Ägypten',
		name_fr: 'Égypte',
		name_it: 'Egitto',
		name_es: 'Egipto',
		name_pt: 'Egito'
	},
	ES: {
		name_en: 'Spain',
		name_de: 'Spanien',
		name_fr: 'Espagne',
		name_it: 'Spagna',
		name_es: 'España',
		name_pt: 'Espanha',
		region: 'europe'
	},
	FI: {
		name_en: 'Finland',
		name_de: 'Finnland',
		name_fr: 'Finlande',
		name_it: 'Finlandia',
		name_es: 'Finlandia',
		name_pt: 'Finlândia',
		region: 'europe'
	},
	FR: {
		name_en: 'France',
		name_de: 'Frankreich',
		name_fr: 'France',
		name_it: 'Francia',
		name_es: 'Francia',
		name_pt: 'França',
		region: 'europe'
	},
	GB: {
		name_en: 'United Kingdom',
		name_de: 'Vereinigtes Königreich',
		name_fr: 'Royaume-Uni',
		name_it: 'Regno Unito',
		name_es: 'Reino Unido',
		name_pt: 'Reino Unido'
	},
	GR: {
		name_en: 'Greece',
		name_de: 'Griechenland',
		name_fr: 'Grèce',
		name_it: 'Grecia',
		name_es: 'Grecia',
		name_pt: 'Grécia',
		region: 'europe'
	},
	HN: {
		name_en: 'Honduras',
		name_de: 'Honduras',
		name_fr: 'Honduras',
		name_it: 'Honduras',
		name_es: 'Honduras',
		name_pt: 'Honduras'
	},
	HR: {
		name_en: 'Croatia',
		name_de: 'Kroatien',
		name_fr: 'Croatie',
		name_it: 'Croazia',
		name_es: 'Croacia',
		name_pt: 'Croácia',
		region: 'europe'
	},
	HU: {
		name_en: 'Hungary',
		name_de: 'Ungarn',
		name_fr: 'Hongrie',
		name_it: 'Ungheria',
		name_es: 'Hungría',
		name_pt: 'Hungria',
		region: 'europe'
	},
	ID: {
		name_en: 'Indonesia',
		name_de: 'Indonesien',
		name_fr: 'Indonésie',
		name_it: 'Indonesia',
		name_es: 'Indonesia',
		name_pt: 'Indonésia'
	},
	IE: {
		name_en: 'Ireland',
		name_de: 'Irland',
		name_fr: 'Irlande',
		name_it: 'Irlanda',
		name_es: 'Irlanda',
		name_pt: 'Irlanda',
		region: 'europe'
	},
	IL: {
		name_en: 'Israel',
		name_de: 'Israel',
		name_fr: 'Israël',
		name_it: 'Israele',
		name_es: 'Israel',
		name_pt: 'Israel'
	},
	IT: {
		name_en: 'Italy',
		name_de: 'Italien',
		name_fr: 'Italie',
		name_it: 'Italia',
		name_es: 'Italia',
		name_pt: 'Itália',
		region: 'europe'
	},
	JP: {
		name_en: 'Japan',
		name_de: 'Japan',
		name_fr: 'Japon',
		name_it: 'Giappone',
		name_es: 'Japón',
		name_pt: 'Japão',
		region: 'asia'
	},
	KR: {
		name_en: 'South Korea',
		name_de: 'Südkorea',
		name_fr: 'Corée du Sud',
		name_it: 'Corea del Sud',
		name_es: 'Corea del Sur',
		name_pt: 'Coreia do Sul'
	},
	LI: {
		name_en: 'Liechtenstein',
		name_de: 'Liechtenstein',
		name_fr: 'Liechtenstein',
		name_it: 'Liechtenstein',
		name_es: 'Liechtenstein',
		name_pt: 'Liechtenstein'
	},
	LT: {
		name_en: 'Lithuania',
		name_de: 'Litauen',
		name_fr: 'Lituanie',
		name_it: 'Lituania',
		name_es: 'Lituania',
		name_pt: 'Lituânia',
		region: 'europe'
	},
	LU: {
		name_en: 'Luxembourg',
		name_de: 'Luxemburg',
		name_fr: 'Luxembourg',
		name_it: 'Lussemburgo',
		name_es: 'Luxemburgo',
		name_pt: 'Luxemburgo',
		region: 'europe'
	},
	LV: {
		name_en: 'Latvia',
		name_de: 'Lettland',
		name_fr: 'Lettonie',
		name_it: 'Lettonia',
		name_es: 'Letonia',
		name_pt: 'Letónia',
		region: 'europe'
	},
	MT: {
		name_en: 'Malta',
		name_de: 'Malta',
		name_fr: 'Malte',
		name_it: 'Malta',
		name_es: 'Malta',
		name_pt: 'Malta',
		region: 'europe'
	},
	MX: {
		name_en: 'Mexico',
		name_de: 'Mexiko',
		name_fr: 'Mexique',
		name_it: 'Messico',
		name_es: 'México',
		name_pt: 'México'
	},
	NI: {
		name_en: 'Nicaragua',
		name_de: 'Nicaragua',
		name_fr: 'Nicaragua',
		name_it: 'Nicaragua',
		name_es: 'Nicaragua',
		name_pt: 'Nicarágua'
	},
	NL: {
		name_en: 'Netherlands',
		name_de: 'Niederlande',
		name_fr: 'Pays-Bas',
		name_it: 'Paesi Bassi',
		name_es: 'Países Bajos',
		name_pt: 'Países Baixos',
		region: 'europe'
	},
	NO: {
		name_en: 'Norway',
		name_de: 'Norwegen',
		name_fr: 'Norvège',
		name_it: 'Norvegia',
		name_es: 'Noruega',
		name_pt: 'Noruega',
		region: 'europe'
	},
	NZ: {
		name_en: 'New Zealand',
		name_de: 'Neuseeland',
		name_fr: 'Nouvelle-Zélande',
		name_it: 'Nuova Zelanda',
		name_es: 'Nueva Zelanda',
		name_pt: 'Nova Zelândia',
		region: 'oceania'
	},
	PE: {
		name_en: 'Peru',
		name_de: 'Peru',
		name_fr: 'Pérou',
		name_it: 'Perù',
		name_es: 'Perú',
		name_pt: 'Peru'
	},
	PH: {
		name_en: 'Philippines',
		name_de: 'Philippinen',
		name_fr: 'Philippines',
		name_it: 'Filippine',
		name_es: 'Filipinas',
		name_pt: 'Filipinas'
	},
	PL: {
		name_en: 'Poland',
		name_de: 'Polen',
		name_fr: 'Pologne',
		name_it: 'Polonia',
		name_es: 'Polonia',
		name_pt: 'Polónia',
		region: 'europe'
	},
	PT: {
		name_en: 'Portugal',
		name_de: 'Portugal',
		name_fr: 'Portugal',
		name_it: 'Portogallo',
		name_es: 'Portugal',
		name_pt: 'Portugal',
		region: 'europe'
	},
	RO: {
		name_en: 'Romania',
		name_de: 'Rumänien',
		name_fr: 'Roumanie',
		name_it: 'Romania',
		name_es: 'Rumanía',
		name_pt: 'Romênia',
		region: 'europe'
	},
	RS: {
		name_en: 'Serbia',
		name_de: 'Serbien',
		name_fr: 'Serbie',
		name_it: 'Serbia',
		name_es: 'Serbia',
		name_pt: 'Sérvia'
	},
	SE: {
		name_en: 'Sweden',
		name_de: 'Schweden',
		name_fr: 'Suède',
		name_it: 'Svezia',
		name_es: 'Suecia',
		name_pt: 'Suécia',
		region: 'europe'
	},
	SI: {
		name_en: 'Slovenia',
		name_de: 'Slowenien',
		name_fr: 'Slovénie',
		name_it: 'Slovenia',
		name_es: 'Eslovenia',
		name_pt: 'Eslovênia',
		region: 'europe'
	},
	SK: {
		name_en: 'Slovakia',
		name_de: 'Slowakei',
		name_fr: 'Slovaquie',
		name_it: 'Slovacchia',
		name_es: 'Eslovaquia',
		name_pt: 'Eslováquia',
		region: 'europe'
	},
	SV: {
		name_en: 'El Salvador',
		name_de: 'El Salvador',
		name_fr: 'El Salvador',
		name_it: 'El Salvador',
		name_es: 'El Salvador',
		name_pt: 'El Salvador'
	},
	TH: {
		name_en: 'Thailand',
		name_de: 'Thailand',
		name_fr: 'Thaïlande',
		name_it: 'Thailandia',
		name_es: 'Tailandia',
		name_pt: 'Tailândia'
	},
	TN: {
		name_en: 'Tunisia',
		name_de: 'Tunesien',
		name_fr: 'Tunisie',
		name_it: 'Tunisia',
		name_es: 'Túnez',
		name_pt: 'Tunísia'
	},
	TR: {
		name_en: 'Türkiye',
		name_de: 'Türkei',
		name_fr: 'Turquie',
		name_it: 'Turchia',
		name_es: 'Turquía',
		name_pt: 'Turquia'
	},
	US: {
		name_en: 'United States',
		name_de: 'Vereinigte Staaten',
		name_fr: 'États-Unis',
		name_it: 'Stati Uniti',
		name_es: 'Estados Unidos',
		name_pt: 'Estados Unidos',
		region: 'northAmerica',
		states: {
			AL: 'Alabama',
			AK: 'Alaska',
			AR: 'Arkansas',
			AZ: 'Arizona',
			CA: 'California',
			CO: 'Colorado',
			CT: 'Connecticut',
			DE: 'Delaware',
			FL: 'Florida',
			GA: 'Georgia',
			HI: 'Hawaii',
			IA: 'Iowa',
			ID: 'Idaho',
			IL: 'Illinois',
			IN: 'Indiana',
			KS: 'Kansas',
			KY: 'Kentucky',
			LA: 'Louisiana',
			MA: 'Massachusetts',
			ME: 'Maine',
			MD: 'Maryland',
			MI: 'Michigan',
			MN: 'Minnesota',
			MO: 'Missouri',
			MS: 'Mississippi',
			MT: 'Montana',
			NE: 'Nebraska',
			NV: 'Nevada',
			NH: 'New Hampshire',
			NJ: 'New Jersey',
			NM: 'New Mexico',
			NY: 'New York',
			NC: 'North Carolina',
			ND: 'North Dakota',
			OH: 'Ohio',
			OK: 'Oklahoma',
			OR: 'Oregon',
			PA: 'Pennsylvania',
			RI: 'Rhode Island',
			SC: 'South Carolina',
			SD: 'South Dakota',
			TN: 'Tennessee',
			TX: 'Texas',
			UT: 'Utah',
			VT: 'Vermont',
			VA: 'Virginia',
			WA: 'Washington',
			WV: 'West Virginia',
			WI: 'Wisconsin',
			WY: 'Wyoming'
		}
	},
	VN: {
		name_en: 'Vietnam',
		name_de: 'Vietnam',
		name_fr: 'Viêt Nam',
		name_it: 'Vietnam',
		name_es: 'Vietnam',
		name_pt: 'Vietnã'
	},
	ZA: {
		name_en: 'South Africa',
		name_de: 'Südafrika',
		name_fr: 'Afrique du Sud',
		name_it: 'Sud Africa',
		name_es: 'Sudáfrica',
		name_pt: 'África do Sul'
	}
}

export default countries
