import { MASTER_DUEL, TCG } from './formats'

const rarityGroups = [
	{
		name: 'general.rarityGroupDefault',
		members: [7, 5, 4, 3, 1],
		format: TCG
	},
	{
		name: 'general.rarityGroupSpecial',
		members: [6, 73, 30, 72, 70],
		format: TCG
	},
	{
		name: 'general.rarityGroupExtremelyRare',
		members: [8, 63, 28, 61, 71],
		format: TCG
	},
	{
		name: 'general.rarityGroupFoilingVariants',
		members: [11, 60],
		format: TCG
	},
	{
		name: 'general.rarityGroupGoldRares',
		members: [9, 18, 10, 29],
		format: TCG
	},
	{
		name: 'general.rarityGroupPlatinumRares',
		members: [12, 14],
		format: TCG
	},
	{
		name: 'general.rarityGroupLetteringVariants',
		members: [62, 75, 76, 31, 32, 33, 69, 74, 59, 55, 52, 54, 56, 58, 57, 35],
		format: TCG
	},
	{
		name: 'general.rarityGroupDuelTerminal',
		members: [27, 26, 25, 24, 23],
		format: TCG
	},
	{
		name: 'general.rarityGroupParallelFoilRares',
		members: [22, 21, 20],
		format: TCG
	},
	{
		name: 'general.rarityGroupOtherFoilRares',
		members: [16, 17, 15],
		format: TCG
	},
	{
		name: 'general.rarityGroupExtremelySpecial',
		members: [19, 34],
		format: TCG
	},
	{
		name: 'general.rarityGroupDefault',
		members: [65, 66, 67, 68],
		format: MASTER_DUEL
	}
]

export default rarityGroups
