import React from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'
import lighten from 'polished/lib/color/lighten'
import TagOld from './TagOld'
import themeDark from '../../styles/theme-dark'

const ProTag = ({ dark, theme, title }) => title !== 'none' ? (
	<Wrapper titleStr={title} overrideTheme={dark ? themeDark : theme}>
		<Inner>Pro</Inner>
	</Wrapper>
) : null

ProTag.propTypes = {
	dark: PropTypes.bool,
	theme: PropTypes.object.isRequired,
	title: PropTypes.string
}

ProTag.defaultProps = {
	dark: false,
	title: null
}

const Inner = styled.span`
	color: #FFF !important;
`

const Wrapper = styled(TagOld)`
	background-color: ${props => props.overrideTheme.primary};
	border: 1px solid ${props => props.overrideTheme.primary};
	border-radius: 2px;
	color: #FFF !important;
	font-size: 0.9em;
	padding: calc(0.1em - 1px) 0.2em;

	${props => props.titleStr === 'gold' && `
		background: ${props.dark || props.overrideTheme.id === 'dark' ? props.overrideTheme.backgroundVeryLight : lighten(0.3, '#d8b066')};
		border: 1px solid rgb(246 196 105 / 40%);

		${Inner} {
			background-image: linear-gradient(295deg, #ffe5a2 5%, #bf841a 50%, #ffcd74 95%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	`}

	${props => props.titleStr === 'rainbow' && `
		background-color: ${props.overrideTheme.backgroundVeryLight};
		border: 1px solid ${props.dark || props.theme.id === 'dark' ? '#333' : 'rgba(0, 0, 0, 0.1)'};

		${Inner} {
			background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
	`}

	${props => props.titleStr === 'black' && `
		background-color: #111;
		border: 1px solid ${props.dark || props.overrideTheme.id === 'dark' ? '#333' : '#111'};
	`}
`

const enhance = withTheme

export default enhance(ProTag)
