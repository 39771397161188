import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'
import EmptyCardImage from '../../../components/card/name/EmptyCardImage'
import Icon from '../../../components/general/Icon'
import AllowedCopies from '../../../components/card/AllowedCopies'
import NotFound from '../../../images/notFound.svg'
import Rarity from '../../../components/card/Rarity'
import rarities from '../../../data/rarities'

class Image extends Component {
	static propTypes = {
		allowed: PropTypes.number,
		allowedSmall: PropTypes.bool,
		gray: PropTypes.bool,
		fixedSize: PropTypes.bool,
		highlight: PropTypes.bool,
		image: PropTypes.string,
		onClick: PropTypes.func,
		rarity: PropTypes.any,
		size: PropTypes.number
	}

	static defaultProps = {
		allowed: null,
		allowedSmall: false,
		gray: false,
		fixedSize: false,
		highlight: false,
		image: null,
		onClick: null,
		rarity: null,
		size: 160
	}

	constructor(props) {
		super(props)

		this.state = {
			loaded: false
		}

		this.imageRef = React.createRef()
	}

	componentDidMount() {
		this.attachErrorListener()
	}

	componentDidUpdate(prevProps) {
		if (prevProps.image !== this.props.image) {
			this.setState({
				loaded: false
			}, () => {
				this.attachErrorListener()
			})
		}
	}

	attachErrorListener = () => {
		if (this.imageRef) {
			const { current } = this.imageRef

			if (current) {
				current.addEventListener('error', this.onError, {
					once: true
				})
			}
		}
	}

	onError = () => {
		const { current } = this.imageRef

		if (current) {
			current.src = NotFound
			current.removeAttribute('srcSet')
		}
	}

	render() {
		const { allowed, allowedSmall, gray, fixedSize, highlight, image, onClick, rarity, size } = this.props
		const { loaded } = this.state

		return (
			<Wrapper
				fixedSize={fixedSize}
				gray={gray}
				onClick={onClick}
				size={size}
			>
				{rarity && (
					<Rarity tiny skewed id={rarity !== 'unknown' ? rarity : null}>
						{(rarities[rarity] && rarities[rarity].abbr) || '?'}
					</Rarity>
				)}
				{image ? (
					<picture>
						<ImageElement
							srcSet={`${image}-${size}.webp 1x, ${image}-${size * 2}.webp 2x, ${image}-${size * 3}.webp 3x`}
							src={`${image}-${size}.webp`}
							draggable={false}
							alt=""
							ref={this.imageRef}
							loaded={loaded || (this.imageRef.current && this.imageRef.current.complete)}
							onLoad={() => this.setState({ loaded: true })}
						/>
					</picture>
				) : (
					<EmptyCardImage />
				)}
				{allowed !== null && allowed < 3 && (
					<StyledAllowedCopies allowed={allowed} small={allowedSmall}>
						{allowed === 0 ? <Icon name="ban" size={16} /> : allowed}
					</StyledAllowedCopies>
				)}
				{highlight && (
					<ComboPiece>
						<Icon name="thumbs-up" size={12} />
					</ComboPiece>
				)}
			</Wrapper>
		)
	}
}

const Wrapper = styled.div`
	position: relative;

	> picture, > picture img, > img {
		${props => props.fixedSize && props.size === 70 && `
			height: 102px;
			width: 70px;
			min-width: 70px;
		`}

		${props => props.fixedSize && props.size === 30 && `
			height: 30px;
			width: 21px;
			min-width: 21px;
		`}

		${props => props.gray && `
			filter: grayscale(100%);
			-webkit-transform: translateZ(0);
			-webkit-perspective: 1000;
			-webkit-backface-visibility: hidden;
		`}
	}
`

const ImageElement = styled.img`
	visibility: ${props => props.loaded ? 'visible' : 'hidden'};
`

const StyledAllowedCopies = styled(AllowedCopies)`
	position: absolute;
	top: -5px;
	left: -5px;
	z-index: ${props => props.theme.zLayer2};
`

const reveal = keyframes`
	0% {
		opacity: 0;
		transform: scale(0.5);
	}

	50% {
		opacity: 1;
		transform: scale(1.3);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
`

const revealAnimation = css`
	${reveal} 0.2s ease forwards;
`

const ComboPiece = styled.div`
	position: absolute;
	left: 8px;
	top: 8px;
	z-index: ${props => props.theme.zLayer2};

	animation: ${revealAnimation};
	animation-delay: 325ms;
	background: #FFF;
	border-radius: 50%;
	color: ${props => props.theme.primary};
	opacity: 0;
	padding: 0.25rem;

	align-items: center;
	display: flex;
	justify-content: center;
`

export default Image
