import { MASTER_DUEL } from './formats'

const rarities = {
	34: {
		name: '10000 Secret Rare',
		abbr: '10K',
		background: 'red',
		order: 1,
		holo: true
	},
	19: {
		name: 'Extra Secret Rare',
		abbr: 'EXSCR',
		background: 'red',
		order: 2,
		holo: true
	},
	8: {
		name: 'Ghost Rare',
		abbr: 'GHR',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 3,
		holo: true
	},
	63: {
		name: 'Starlight Rare (Astral glyphs)',
		abbr: 'SLRA',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 4,
		holo: true
	},
	28: {
		name: 'Starlight Rare',
		abbr: 'SLR',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 5,
		holo: true
	},
	71: {
		name: 'Pharaoh\'s Rare (Secret Rare foiling)',
		abbr: 'PHRS',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 6,
		holo: true
	},
	61: {
		name: 'Pharaoh\'s Rare (Ultra Rare foiling)',
		abbr: 'PHRU',
		background: 'backgroundInverted',
		color: 'textInverted',
		order: 7,
		holo: true
	},
	18: {
		name: 'Ghost Gold Rare',
		abbr: 'GHGR',
		background: 'gold2',
		color: 'textInverted',
		borderColor: 'backgroundInverted',
		order: 8,
		holo: true
	},
	70: {
		name: 'Quarter Century Secret Rare',
		abbr: 'QCSCR',
		background: 'teal',
		order: 9,
		holo: true
	},
	30: {
		name: 'Collector\'s Rare',
		abbr: 'CR',
		background: 'teal',
		order: 10,
		holo: true
	},
	6: {
		name: 'Ultimate Rare',
		abbr: 'UMR',
		background: 'teal',
		order: 11,
		holo: true
	},
	72: {
		name: 'Prismatic Collector\'s Rare',
		abbr: 'PMCR',
		background: 'teal',
		order: 12,
		holo: true
	},
	73: {
		name: 'Prismatic Ultimate Rare',
		abbr: 'PMUR',
		background: 'teal',
		order: 13,
		holo: true
	},
	14: {
		name: 'Platinum Secret Rare',
		abbr: 'PLSCR',
		background: 'platinum',
		borderColor: 'purple',
		color: 'textLight',
		order: 14,
		holo: true
	},
	11: {
		name: 'Prismatic Secret Rare',
		abbr: 'PMSCR',
		background: 'purple',
		order: 15,
		holo: true
	},
	7: {
		name: 'Secret Rare',
		abbr: 'SCR',
		background: 'purple',
		order: 16,
		holo: true
	},
	62: {
		name: 'Blue Secret Rare',
		abbr: 'BLSCR',
		background: 'purple',
		order: 17,
		holo: true
	},
	27: {
		name: 'Duel Terminal Secret Rare',
		abbr: 'DTSCR',
		background: 'purple',
		borderColor: 'middleBlueGreen',
		order: 18,
		holo: true
	},
	10: {
		name: 'Gold Secret Rare',
		abbr: 'GSCR',
		background: 'gold2',
		borderColor: 'purple',
		order: 19,
		holo: true
	},
	75: {
		name: 'Ultra Rare (Astral glyphs)',
		abbr: 'URA',
		background: 'gold',
		order: 20,
		holo: true
	},
	76: {
		name: 'Silver Ultra Rare (Astral glyphs)',
		abbr: 'SURA',
		background: 'gold',
		order: 21,
		holo: true
	},
	5: {
		name: 'Ultra Rare',
		abbr: 'UR',
		background: 'gold',
		order: 22,
		holo: true
	},
	60: {
		name: 'Duelist Saga Ultra Rare',
		abbr: 'DSUR',
		background: 'gold',
		order: 23,
		holo: true
	},
	31: {
		name: 'Blue Ultra Rare',
		abbr: 'BLUR',
		background: 'gold',
		order: 24,
		holo: true
	},
	32: {
		name: 'Green Ultra Rare',
		abbr: 'GRUR',
		background: 'gold',
		order: 25,
		holo: true
	},
	33: {
		name: 'Purple Ultra Rare',
		abbr: 'PUUR',
		background: 'gold',
		order: 26,
		holo: true
	},
	69: {
		name: 'Red Ultra Rare',
		abbr: 'REUR',
		background: 'gold',
		order: 27,
		holo: true
	},
	74: {
		name: 'Silver Ultra Rare',
		abbr: 'SUR',
		background: 'gold',
		order: 28,
		holo: true
	},
	26: {
		name: 'Duel Terminal Ultra Rare',
		abbr: 'DTUR',
		background: 'gold',
		borderColor: 'middleBlueGreen',
		order: 29,
		holo: true
	},
	22: {
		name: 'Parallel Ultra Rare',
		abbr: 'PUR',
		background: 'gold',
		borderColor: 'middleBlueGreen',
		order: 30,
		holo: true
	},
	29: {
		name: 'Premium Gold Rare',
		abbr: 'PGR',
		background: 'gold2',
		order: 31,
		holo: true
	},
	9: {
		name: 'Gold Rare',
		abbr: 'GR',
		background: 'gold2',
		order: 32,
		holo: true
	},
	12: {
		name: 'Platinum Rare',
		abbr: 'PLR',
		background: 'platinum',
		color: 'textLight',
		order: 33,
		holo: true
	},
	4: {
		name: 'Super Rare',
		abbr: 'SR',
		background: 'bronze',
		order: 34,
		holo: true
	},
	25: {
		name: 'Duel Terminal Super Rare',
		abbr: 'DTSR',
		background: 'bronze',
		borderColor: 'middleBlueGreen',
		order: 35,
		holo: true
	},
	21: {
		name: 'Parallel Super Rare',
		abbr: 'PSR',
		background: 'bronze',
		borderColor: 'middleBlueGreen',
		order: 36,
		holo: true
	},
	24: {
		name: 'Duel Terminal Rare',
		abbr: 'DTR',
		background: 'silver',
		borderColor: 'middleBlueGreen',
		order: 37,
		holo: false
	},
	23: {
		name: 'Duel Terminal Common',
		abbr: 'DTC',
		background: 'backgroundLight',
		borderColor: 'middleBlueGreen',
		color: 'textLight',
		order: 38,
		holo: false
	},
	20: {
		name: 'Parallel Common',
		abbr: 'PC',
		background: 'backgroundLight',
		borderColor: 'middleBlueGreen',
		color: 'textLight',
		order: 39,
		holo: false
	},
	15: {
		name: 'Shatterfoil Rare',
		abbr: 'SHR',
		background: 'silver',
		borderColor: 'middleBlueGreen',
		order: 40,
		holo: false
	},
	17: {
		name: 'Mosaic Rare',
		abbr: 'MSR',
		background: 'silver',
		borderColor: 'middleBlueGreen',
		order: 41,
		holo: false
	},
	16: {
		name: 'Starfoil Rare',
		abbr: 'SFR',
		background: 'silver',
		borderColor: 'middleBlueGreen',
		order: 42,
		holo: false
	},
	3: {
		name: 'Rare',
		abbr: 'R',
		background: 'silver',
		order: 43,
		holo: false
	},
	35: {
		name: 'Gold Letter Rare',
		abbr: 'GLR',
		background: 'silver',
		order: 44,
		holo: false
	},
	59: {
		name: 'Black/White Rare',
		abbr: 'BWR',
		background: 'silver',
		order: 45,
		holo: false
	},
	55: {
		name: 'Blue Rare',
		abbr: 'BLR',
		background: 'silver',
		order: 46,
		holo: false
	},
	52: {
		name: 'Bronze Rare',
		abbr: 'BRR',
		background: 'silver',
		order: 47,
		holo: false
	},
	54: {
		name: 'Light Blue Rare',
		abbr: 'LBR',
		background: 'silver',
		order: 48,
		holo: false
	},
	56: {
		name: 'Green Rare',
		abbr: 'GRR',
		background: 'silver',
		order: 49,
		holo: false
	},
	58: {
		name: 'Purple Rare',
		abbr: 'PRR',
		background: 'silver',
		order: 50,
		holo: false
	},
	57: {
		name: 'Red Rare',
		abbr: 'RER',
		background: 'silver',
		order: 51,
		holo: false
	},
	1: {
		name: 'Common',
		abbr: 'C',
		background: 'backgroundLight',
		color: 'textLight',
		order: 52,
		holo: false
	},
	65: {
		name: 'Ultra Rare',
		abbr: 'UR',
		background: 'purpleToTeal',
		skewedColor: '#B52DFF',
		order: 1,
		format: MASTER_DUEL
	},
	66: {
		name: 'Super Rare',
		abbr: 'SR',
		background: 'orange',
		order: 2,
		format: MASTER_DUEL
	},
	67: {
		name: 'Rare',
		abbr: 'R',
		background: 'blue',
		order: 3,
		format: MASTER_DUEL
	},
	68: {
		name: 'Normal',
		abbr: 'N',
		background: 'backgroundLight',
		color: 'textLight',
		order: 4,
		format: MASTER_DUEL
	}
}

export default rarities
