import styled from 'styled-components'
import darken from 'polished/lib/color/darken'
import { StyledIcon } from '../general/Icon'
import Link from '../router/Link'
import { desktop, mobile } from '../../styles/media'

export const ToolbarButtonCollapseButtonRight = styled.div`
	align-items: center;
	display: flex;

	padding-left: 0.5rem;

	svg:last-child {
		color: ${props => props.theme.textVeryLight};
		transition: 0.2s;

		${props => props.active && `
			transform: rotate(-180deg);
		`}
	}

	${mobile`
		margin-left: auto;
		padding-left: 0.25rem;

		svg:last-child {
			height: 16px;
			width: 16px;
		}
	`}
`

const ToolbarButton = styled(Link)`
	background: ${props => props.theme.backgroundLight};
	border: 1px solid transparent;
	border-radius: 12px;
	color: ${props => props.theme.textLight};
	font-weight: 600;
	padding: 0 0.75rem;
	text-decoration: none;
	transition: color 0.1s;
	white-space: nowrap;
	user-select: none;

	align-items: center;
	display: flex;
	justify-content: center;

	height: 34px;
	min-width: 34px;

	${props => props.chevron && `
		border-radius: 12px 0 0 12px;
	`}

	${props => props.active && !props.filterActive && `
		background: ${props.theme.id === 'dark' ? (props.primary ? props.theme.primaryHover : props.theme.backgroundDark) : darken(0.1, props.primary ? props.theme.primary : props.theme.backgroundLight)};
		color: ${props.primary ? props.theme.white : props.theme.text} !important;
	`}

	${props => (props.primary || props.green) && `
		transition: background 0.1s, color 0.1s;
	`}

	${desktop`
		&:hover {
			color: ${props => props.primary || props.secondary || props.green ? props.theme.white : props.theme.text};

			${props => props.primary && `
				background: ${props.theme.primaryHover};
			`}

			${props => props.green && `
				background: ${props.theme.greenHover};
			`}
		}
	`}

	> ${StyledIcon} {
		height: 20px;
		width: 20px;
		min-width: 20px;
		margin-right: 0.35rem;

		${props => props.red && `
			color: ${props.theme.red};
		`}
	}

	${mobile`
		border-radius: 8px;
		font-size: 0.8rem;
		height: 30px;
		min-width: 30px;
		padding: 0 0.5rem;

		${props => props.chevron && `
			border-radius: 8px 0 0 8px;
		`}

		> ${StyledIcon} {
			height: 16px;
			width: 16px;
			min-width: 16px;
			margin-right: 0.25rem;
		}
	`}

	${props => props.hasText && `
		> ${StyledIcon} {
			margin-right: 0.5rem;
		}
	`}

	${props => props.primary && `
		background: ${props.theme.primary};
		color: ${props.theme.white};
	`}

	${props => props.green && `
		background: ${props.theme.green};
		color: ${props.theme.white};
	`}

	${props => props.red && `
		color: ${props.theme.red};
	`}

	${props => props.secondary && `
		background: ${props.theme.secondary};
		color: #DDD;
	`}

	${props => props.small && `
		height: 28px;
	`}

	${props => props.icon && `
		padding: 0;

		svg {
			margin-right: 0 !important;
		}
	`}

	${props => props.disabled && `
		opacity: 0.5;
		pointer-events: none;
	`}

	${props => props.filterActive && `
		border: 2px solid ${props.theme.primary};

		svg {
			color: ${props.theme.primary};
		}
	`}
`

export const ToolbarButtonCount = styled.div`
	height: 20px;
	min-width: 20px;
	margin-left: 0.35rem;
	padding: 0 0.25rem;

	align-items: center;
	display: flex;
	justify-content: center;

	font-size: 0.6rem;
	font-weight: 600;

	background: ${props => props.theme.primary};
	border-radius: 50%;
	color: ${props => props.theme.white};
`

export default ToolbarButton
